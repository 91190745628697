$btn-padding : 0.5rem 1.5rem;

/**=====================
    Admin CSS Start
==========================**/
p {
    font-size: 14px;
    color: $grey;
    line-height: 1;
}
h6 {
    font-size: 14px;
    font-weight: 400;
    color: $grey;
    line-height: 24px;
}
h3 {
    font-size: 24px;
    font-weight: 400;
    color: $grey;
    letter-spacing: 0.03em;
}
h2 {
    font-size: 36px;
    color: $dark-font;
    text-transform: uppercase;
    font-weight: 700;
    line-height: 1;
    letter-spacing: 0.02em;
}
h4 {
    font-size: 18px;
    text-transform: capitalize;
    font-weight: 400;
    letter-spacing: 0.03em;
    line-height: 1;
}
h5 {
    font-size: 16px;
    font-weight: 400;
    color: $dark-font;
    line-height: 24px;
    letter-spacing: 0.05em;
}
del {
    font-size: 14px;
    color: $font-grey;
    font-weight: 400;
}
@keyframes sidebar-menu-open {
  0% {
    transform: translate(0px, -50%) scale(1, 0);
  }
  100% {
    transform: translate(0px, 0%) scale(1, 1);
  }
}
.chart-sparkline {
  position: absolute;
  left: 0;
  top: 0;
  visibility: hidden;
  background: rgba(49, 49, 49, 0.70);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=rgba(153, 0, 0, 0), endColorstr=rgba(153, 0, 0, 0));
  color: $white;
  font: 10px $font-nunito, $font-serif;
  text-align: left;
  white-space: nowrap;
  padding: 5px;
  border: 1px solid rgba(49, 49, 49, 0.75);;
  box-sizing: content-box;
  z-index: 10000;
  border-radius: 5px;
  .jqsfield {
    color: $white;
    font: 10px $font-nunito, $font-serif;
    text-align: left;
  }
}
.checkbox_animated {
  cursor: pointer;
  position: relative;
  margin: 0 1rem 0 0;
  &:before {
    transition: transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
    transform: rotate(-45deg) scale(0, 0);
    content: "";
    position: absolute;
    left: 0.25rem;
    top: 0.225rem;
    z-index: 1;
    width: 0.75rem;
    height: 0.375rem;
    border: 2px solid $primary-color;
    border-top-style: none;
    border-right-style: none;
  }
  &:after {
    content: "";
    position: absolute;
    top: -0.125rem;
    left: 0;
    width: 1.3rem;
    height: 1.3rem;
    background: $white;
    border: 2px solid $light-gray;
    cursor: pointer;
  }
  &:checked:before {
    transform: rotate(-45deg) scale(1, 1);
  }
}
.radio_animated {
  position: relative;
  margin: 0 1rem 0 0;
  cursor: pointer;
  &:before {
    transition: transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
    transform: scale(0, 0);
    content: "";
    position: absolute;
    top: 0;
    left: 0.125rem;
    z-index: 1;
    width: 0.75rem;
    height: 0.75rem;
    background: $primary-color;
    border-radius: 50%;
  }
  &:after {
    content: "";
    position: absolute;
    top: -0.25rem;
    left: -0.125rem;
    width: 1.25rem;
    height: 1.25rem;
    background: $white;
    border: 2px solid $light-gray;
    border-radius: 50%;
  }
  &:checked:before {
    transform: scale(1, 1);
  }
}
a {
  &:hover {
    color: $primary-color;
    text-decoration: none;
  }
}
.form-control {
  &:focus {
    box-shadow: none;
  }
}
:focus {
  outline-color: transparent;
  outline: 0;
}
.page-link{
  &:focus {
    box-shadow: none;
  }
}
.btn-popup {
  margin-bottom: 30px;
}
.media-table {
  table {
    tr {
      th,td {
        &:last-child {
          text-align: left !important;
        }
      }
    }
  }
}
.ngx-charts {
  float: none !important;
}
.imgTable {
  width: 50px !important;
  height: 50px;
  object-fit: cover;
}
input[type="file"] {
  height: 45px !important;
}
.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: 0;
}
.ck.ck-toolbar {
  border-color: #ddd !important;
}
.ck {
  &.ck-editor__editable_inline {
    >:first-child {
      margin-top: 0 !important;
      padding: 11px;
    }
  }
}
.ck-rounded-corners .ck.ck-editor__main>.ck-editor__editable,
 .ck.ck-editor__main>.ck-editor__editable.ck-rounded-corners {
  border-bottom-left-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
 }

.ck-rounded-corners {
  .ck.ck-editor__top {
    .ck-sticky-panel {
      .ck-toolbar {
        border-top-left-radius: 5px !important;
        border-top-right-radius: 5px !important;
      }
    }
  }
}
.ck {
  &.ck-editor__main {
    >.ck-editor__editable:not(.ck-focused) {
      border-color: #ddd !important;
    }
  }
}
.btn {
  &.calendar {
    background-color: #dee2e6;
    padding: 7px 12px !important;
  }
}
.input-grp-p {
  padding: 0;
}
.order-graph {
  .ct-4 {
    x-chartist {
      svg {
        height: 450px !important;
      }
    }
  }
}
.custom-datatable {
  i {
    font-size: 20px;
    &.fa-edit {
      color: #22af47;
    }
    &.fa-trash-o {
      color: #ff5370;
    }
  }
}
.table {
  > :not(caption) > * > * {
    vertical-align: middle;
  }
}
.table {
  > :not(caption) > * > * {
    padding: 16px 10px;
  }
}
// date picker
ngb-datepicker {
  .ngb-dp-navigation-select {
    .custom-select {
      &:first-child {
        margin-right: 15px;
      }
    }
  }
  .ngb-dp-today {
    color: $black !important;
  }
  .ngb-dp-weekday {
    color: $black;
    font-style: normal !important;
  }
  .ngb-dp-weekdays {
    border-bottom: none !important;
  }
  .ngb-dp-arrow, .ngb-dp-day, .ngb-dp-week-number, .ngb-dp-weekday {
    .btn-light {
      font-size: 14px;
    }
    .btn-link {
      color: $primary-color;
    }
  }
  .ngb-dp-day {
    // .btn-light {
    //   background-color: $white !important;
    // }
    // .bg-primary {
    //   background-color: $primary-color !important;
    // }
  }
}
.ngb-dp-header, .ngb-dp-weekdays.bg-light {
  background-color: $white !important;
}
// datatable
.vendor-list {
  img {
    margin-right: 20px;
    height: 40px;
    width: 40px !important;
    border-radius: 100%;
    object-fit: cover;
  }
}
.user-image {
  .imgTable {
    border-radius: 100%;
  }
}
.menu-list-table, .user-image {
  table {
    tr {
      th,td {
        text-align: center;
      }
    }
  }
}
.media-datatable {
  table {
    tr {
      th,td {
        text-align: left !important;
        &:nth-child(2), &:first-child {
          text-align: center !important;
        }
      }
    }
  }
}
.ngx-datatable {
  .datatable-body-cell {
    padding: .5rem 0.75rem;
  }
}
ngx-datatable {
  .datatable-header {
    padding: 0.75rem;
  }
  &.bootstrap {
    .datatable-header, .datatable-body {
      border: 1px solid #e8efff !important;
    }
  }
}
ng2-smart-table {
  table {
    border: 1px solid #e8efff;
    .ng2-smart-titles {
      background-color: $light-body-bg-color;
      .ng2-smart-th {
        padding: 0.75rem;
      }
    }
    .ng2-smart-sort-link {
      color: $black;
      &:hover {
        text-decoration: none;
      }
    }
    .ng2-smart-action {
      font-size: 0;
      &:before {
        font-family: $font-awesome;
        font-size: 17px;
      }
      &:hover {
        text-decoration: none;
      }
    }
    .ng2-smart-action-add-add {
      &:before {
        content: "\f067";
        color: #22af47;
        font-size: 19px;
      }
    }
    .ng2-smart-action-edit-edit {
      &:before {
        content: "\f040";
        color: #22af47;
      }
    }
    .ng2-smart-action-edit-save {
      &:before {
        content: "\f021";
        color: #4466f2;
      }
    }
    .ng2-smart-action-edit-cancel {
      &:before {
        content: "\f00d";
        color: #ff5370;
      }
    }
    .ng2-smart-action-delete-delete {
      &:before {
        content: "\f1f8";
        color: #ff5370;
      }
    }
    tr {
      th,td {
        border: 1px solid #e8efff;
        &:last-child {
          text-align: center;
        }
      }
      &:nth-child(even) {
        td {
            background-color: rgba(241,244,251,0.5);
        }
      }
      &:nth-child(odd) {
        &:hover {
          td {
              background-color: rgba(241,244,251,0.5);
          }
        }
      }
    }
  }
  ng2-smart-table-pager {
    margin-top: 30px;
    justify-content: flex-end !important;
  }
  .ng2-smart-pagination {
    .page-link-next, .page-link-prev {
      font-size: 14px !important;
    }
  }
}
.page-item {
  .page-link {
    color: $primary-color;
    &:hover {
      text-decoration: none;
    }
  }
  &.active {
    .page-link {
      background-color: $primary-color;
      border-color: $primary-color;
    }
  }
}
// login
.single-item {
  .owl-theme {
    .owl-dots {
      .owl-dot {
        &.active, &:hover {
          span {
            background: $white;
          }
        }
        span {
          background: rgba($white, 0.5);
        }
      }
    }
  }
}
.auth-form {
    .form-control {
      border-radius: 25px;
      padding:  calc(10px + (20 - 10) * ((100vw - 320px) / (1920 - 320))) calc(15px + (25 - 15) * ((100vw - 320px) / (1920 - 320)));
      border: 1px solid #eaeaea;
      border-color: grey;
    }
}
.card-left {
  z-index: 1;
}
.card-right {
    margin-left: -15px;
    .card {
      padding-left: 15px;
    }
}
.authentication-box {
    min-width: 100vw;
    min-height: 100vh;
    width: auto;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    .back-btn {
      svg {
        position: absolute;
        left: 30px;
        height: 18px;
      }
    }
    .row {
      align-items: center;
    }
    .tab2-card {
      .nav-tabs {
        .nav-link {
            font-size: 18px;
            padding-top: 0;
            margin-top: -6px;
        }
      }
    }
    .form-footer {
      position: relative;
      padding-top: 25px;
      border-top: 1px solid #f3f3f3;
      margin-top: 25px;
      .social {
        margin-bottom: 0 !important;
        border-bottom: none;
        li {
          padding: 10px;
          border-radius: 100%;
          border: 1px solid $primary-color;
          height: 40px;
          width: 40px;
          margin-right: 10px;
          text-align: center;
          display: inline-block;
          a {
            text-decoration: none;
          }
        }
      }
      >span {
        position: absolute;
        top: -11px;
        padding-right: 20px;
        background-color: $white;
        color: #909090;
      }
    }
    .back-btn {
      float: right;
      margin: 0;
      font-size: 14px;
      position: relative;
      padding-left: 60px !important;
    }
    .btn-primary {
        border-radius: 25px;
        margin-top: 20px;
        font-weight: 400;
        padding: 11px 45px;
    }
  .container {
    max-width: 900px;
    .form-group {
      margin-bottom: 1.5rem;
    }
    p {
      color: rgba($white, 0.9);
      font-size: 15px;
      line-height: 2;
      text-align: center;
    }
    h3 {
      color: $white;
      font-weight: 600;
      text-align: center;
    }
    .bg-primary {
      padding: 50px;
      background-image: url(../../images/dashboard/login-bg.png);
      background-position: center;
      box-shadow: 1px 5px 24px 0 rgba(255,128,132,0.8);
    }
    .svg-icon {
      padding: 24px;
      margin: 0 auto;
      border: 2px dashed $white;
      border-radius: 100%;
      height: 130px;
      width: 130px;
      margin-bottom: 40px;
      svg {
        height: 80px;
      }
    }
  }
}
.forgot-pass {
    padding: 0 !important;
    float: right;
    color: $primary-color;
}
// category
.product-physical {
  table {
    .jsgrid-filter-row {
      display: none !important;
    }
    tr {
      th,td {
        &:nth-child(2) {
          text-align: left;
        }
      }
    }
  }
}
.timer{
  padding-left: 10px;
  padding-right: 10px;
  span{
    text-align: center;
    position: relative;
    .padding-l{
      padding-left: 0;
      position: absolute;
      left: 35px;
      top: 10px;
    }
  }
}
.product-buttons {
  padding: 0;
  margin: -5px;
  button {
    margin: 5px;
  }
}
.qty-box1 {
  .input-group {
    .btnGtr1, .btnLess1 {
      padding: 12px;
      border: 1px solid $round-border;
    }
    .btnGtr1 {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }
    .btnLess1 {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }
  input {
    padding: 0.48rem 0.75rem;
    border: 1px solid $round-border;
    width: 80px;
    border-left: none;
    border-right: none;
  }
}
.add-product {
  img {
    width: 500px;
  }
  .imgUpload {
    width: 50px;
  }
  ul {
    li {
      display: flex;
      margin-bottom: 15px;
      .box-input-file {
        width: 50px;
        height: 50px;
        background-color: #f1f4fb;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        vertical-align: middle;
        i{
          color: $primary-color;
        }
        .upload {
          position: absolute;
          width: 70px;
          left: 0;
          right: 0;
          opacity: 0;
        }

      }
    }
  }
}
.digital-add {
  .col-form-label {
    font-family: $font-nunito;
  }
  .form-control {
    font-size: 14px;
  }
  .form-group {
    margin-bottom: 25px;
    .radio_animated {
      margin-right: 8px;
    }
  }
  textarea {
    width: 100%;
  }
}
.add-product-form {
  .form-group {
    align-items: flex-start !important;
    margin-bottom: 25px;
    &:last-child {
      align-items: center !important;
    }
    label {
      font-size: 16px;
      font-weight: 600;
    }
    .form-control {
      font-size: 14px;
    }
  }
  .qty-box {
    .input-group {
      justify-content: flex-start;
      button {
        padding: 12px;
      }
      .btn-primary {
        background-color: $transparent-color !important;
        border: 1px solid #ddd !important;
        &.bootstrap-touchspin-down {
          border-right: none !important;
        }
      }
      .input-group-append {
        margin-left: 0;
        .btn {
          border-left: none !important;
        }
      }
    }
  }
  #cke_editor1 {
    .cke_inner {
      .cke_contents {
        &.cke_reset {
          height: 100px !important;
        }
      }
    }
  }
}
.zoomContainer{
  top: 225px !important;
}
.chart-block canvas{
  width: 100%;
}
.sell-graph {
  canvas {
    width: 100% !important;
    height: 300px !important;
  }
}
.sales-carousel {
  .value-graph {
    h3 {
      color: $theme-body-font-color;
      font-weight: 600;
    }
  }
  // .media {
    .small-box {
      width: 50px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  // }
}
.small-chartjs {
  canvas {
    width: 100% !important;
    height: 60px !important;
  }
}
.order-graph {
  h6 {
    color: $theme-body-font-color;
    font-family: $font-nunito;
    font-weight: 700;
    margin-bottom: 20px;
  }
  .order-graph-bottom {
    margin-top: 30px;
    h6 {
      color: $light-text;
      margin-left: 15px;
      margin-right: 30px;
      span {
        color: $theme-body-font-color;
      }
    }
    .media {
      margin-bottom: 20px;
      display: flex;
      &:last-child {
        margin-bottom: 0;
      }
      .media-body {
        width: calc(100% - 8px);
      }
      @each $shape-name, $shape-color in (primary, $primary-color),
              (secondary, $secondary-color) ,
              (success, $success-color),
              (danger, $danger-color),
              (info, $info-color),
              (light, $light-color),
              (dark, $dark-color),
              (warning, $warning-color) {
        .order-shape-#{$shape-name}{
          background-color: $shape-color;
          width: 18px;
          height: 8px;
          margin-top: 7px;
          border-radius: 4px;
        }
      }
    }
    @each $order-name, $order-color in (primary, $primary-color),
            (secondary, $secondary-color) ,
            (success, $success-color),
            (danger, $danger-color),
            (info, $info-color),
            (light, $light-color),
            (dark, $dark-color),
            (warning, $warning-color) {
      .order-color-#{$order-name}{
        background-color: $order-color;
        width: 8px;
        height: 8px;
        margin-top: 7px;
        border-radius: 2px;
      }
    }
  }
}
.datepickers-container {
  top: -86px;
}
.datepicker--nav{
  color: $primary_color;
}
.datepicker--nav-action{
  color: $primary_color;
  background-color:$primary_color ;
}
.datepicker--nav-action:hover{
  color: $primary_color;
}
.datepicker--nav-title i{
  color: $primary_color;
}
.table{
  thead{
    th {
      border-bottom: 2px solid $light-gray;
    }
  }
}
.switch-sm {
  .switch {
    width: 25px;
    height: 16px;
    margin-top: 10px;
    margin-bottom: 0px;
  }
}

.page-wrapper {
  .page-body-wrapper{
    .page-sidebar{
      &.open{
        ~ .page-body{
          .activity {
            .media {
              .gradient-round{
                &.gradient-line-1{
                  &:after {
                    bottom: -66px;
                  }
                }
              }
            }
          }
        }
      }
    }
    footer {
      p {
        line-height: 1.7;
      }
    }
  }
}
.page-wrapper {
  .page-body-wrapper {
    .page-sidebar {
      .sidebar-menu {
        li {
          width: 100%;
        }
      }
    }
    .page-header{
      .row{
        h3{
          color: #000;
        }
      }
    }
  }
}

.widget-cards {
  border-radius: 25px !important;
}
.static-top-widget {
  div.align-self-center{
    svg{
      width: 25px;
      height: 25px;
      vertical-align: middle;
    }
  }
  .media-body {
    align-self: center !important;
    h3 {
      font-family: $font-work-sans, $font-serif;
      color: $white;
      small {
        font-size: 11px;
      }
    }
  }
  .icons-widgets {
    .text-center {
      width: 50px;
      height: 50px;
      border-radius: 100%;
      background-color: $white;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
.flot-chart-container{
  height:400px;
}
.user-status{
  table{
    tbody{
      tr{
        td{
          vertical-align: middle;
          .d-inline-block{
            margin-top: 11px;
          }
          .image-sm-size{
            img{
              width: 41px;
              height: 41px;
              object-fit: cover;
            }
          }
        }
      }
    }
    thead{
      tr{
        th{
          border-top:0;
          font-size: 16px;
          color: $dark-color;
          font-weight: 600;
          padding-top: 0;
        }
      }
    }
  }
}

.card-block{
  .table-responsive{
    .table{
      caption{
        padding-left: 10px;
      }
    }
    .table-bordered{
      td{
        vertical-align: middle;
      }
    }
  }
  .table-border-radius{
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  .default-checkbox-align{
    #checkbox1{
      margin-right: 10px;
    }
    #radio{
      margin-right: 5px;
    }
  }
}

.chart-vertical-center{
  display: flex;
  justify-content: center;
}
// product list
.products-admin {
  .product-box {
    padding: 20px;
    .color-variant {
      padding-top: 15px;
    }
    .img-wrapper {
      position: relative;
      overflow: hidden;
      .lable-block {
        .lable3 {
          border-radius: 100%;
          background-color: $primary-color;
          text-align: center;
          font-size: 14px;
          font-weight: 700;
          position: absolute;
          padding: 12px 6px;
          text-transform: uppercase;
          color: $white;
          top: 7px;
          left: 7px;
          z-index: 1;
        }
        .lable4 {
          position: absolute;
          font-size: 14px;
          font-weight: 700;
          text-transform: uppercase;
          writing-mode: vertical-rl;
          transform: rotate(-180deg);
          top: 7px;
          right: 7px;
          letter-spacing: 0.1em;
          z-index: 1;
        }
      }
    }
    .product-detail, .product-info {
      .rating {
        margin-top: 15px;
        i {
          padding-right: 5px;
          color: #ffa200;
          &:last-child {
            color: #dddddd;
          }
        }
      }
      a {
        text-decoration: none;
        &:hover {
          text-decoration: none;
        }
      }
      h6 {
        line-height: 1;
        margin-bottom: 0;
        padding-top: 2px;
        padding-bottom: 5px;
        transition: all 0.5s ease;
        font-size: 16px;
      }
      h4 {
         font-size: 18px;
         color: $dark-font;
         font-weight: 700;
         margin-bottom: 0;
         transition: all 0.5s ease;
      }
    }
    .front {
      position: relative;
      .product-hover {
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba($white,0.7);
        opacity: 0;
        border-radius: 100%;
        transform: scale(0);
        transition: all 0.3s ease;
        ul {
          li {
            display: inline-block;
            box-shadow: 1px 1px 2px 1px rgba($black,0.08);
            padding: 9px 14px;
            background-color: $white;
            font-size: 18px;
            border-radius: 100%;
            line-height: 1.6;
            height: 45px;
            width: 45px;
            margin: 0 3px;
            .btn {
              padding: 0;
              background-color: $transparent-color;
              i {
                font-size: 16px;
              }
            }
          }
        }
      }
      &:hover {
        .product-hover {
          opacity: 1;
          border-radius: 0%;
          transform: scale(1);
          transition: all 0.3s ease;
        }
      }
    }
  }
  .product-wrap {
    .img-wrapper {
      position: relative;
      overflow: hidden;
      .lable-block {
        .lable3 {
          border-radius: 100%;
          background-color: $primary-color;
          text-align: center;
          font-size: 14px;
          font-weight: 700;
          position: absolute;
          padding: 12px 6px;
          text-transform: uppercase;
          color: $white;
          top: 7px;
          left: 7px;
          z-index: 1;
        }
        .lable4 {
          position: absolute;
          font-size: 14px;
          font-weight: 700;
          text-transform: uppercase;
          writing-mode: vertical-rl;
          transform: rotate(-180deg);
          top: 7px;
          right: 7px;
          letter-spacing: 0.1em;
          z-index: 1;
        }
      }
    }
  }
}
.color-variant {
  li {
    display: inline-block;
    height: 20px;
    width: 20px;
    border-radius: 100%;
    margin-right: 5px;
    transition: all 0.1s ease;
    vertical-align: middle;
  }
}
.bg-light0 {
    background-color: #d0edff;
}
.bg-light1 {
    background-color: #f1e7e6;
}
.bg-light2 {
    background-color: #bfbfbf;
}
.rating-sec {
  gap: 5px;
}
hr {
  opacity: 0.08;
}
.product-right {
  h2 {
    text-transform: uppercase;
    margin-bottom: 3px;
    font-size: 25px;
    line-height: 1.2em;
  }
  h3 {
    font-size: 26px;
    color: $dark-font;
    margin-bottom: 15px;
  }
  .timer {
    margin-top: 10px;
    padding: 15px 10px;
    background-color: $grey-about;
    display: inline-block;
    p {
      font-size: 18px;
      color: $dark-font;
    }
    span {
      width: 70px;
      display: inline-block;
      .timer-cal {
        font-size: 12px;
        color: $theme-body-sub-title-color;
      }
    }
  }
  .size-box {
    margin-top: 10px;
    margin-bottom: 10px;
    ul {
      li {
        display: inline-block;
        height: 35px;
        width: 35px;
        border-radius: 50%;
        margin-right: 10px;
        cursor: pointer;
        border: 1px solid $grey-about;
        text-align: center;
        &.active {
          background-color: $grey-about;
        }
        a {
          color: $dark-font;
          font-size: 18px;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;
          text-decoration: none;
        }
      }
    }
  }
  .color-variant {
     li {
       height: 30px;
       width: 30px;
       cursor: pointer;
     }
  }
  .product-title {
    color: $dark-font;
    text-transform: capitalize;
    font-weight: 700;
    margin-bottom: 6px;
  }
}
#carousel-container {
  > .current-figure {
    > #current-image, > #current-image-legacy {
      margin-bottom: 30px;
    }
  }
}
// product Detail
.product-page-main {
  .product-slider {
    .owl-stage-outer {
      margin-top: 0;
    }
    .owl-carousel {
      .owl-stage-outer {
        .owl-stage {
          .owl-item {
            &.active {
              &.current {
                border: 1px solid $primary-color;
              }
            }
          }
        }
      }
    }
  }
  .owl-stage-outer {
    margin-top: 30px;
    .owl-stage {
      .owl-item {
        &.active {
          &.current {
            border: 1px solid $primary-color !important;
          }
        }
      }
    }
  }
  .product-page-details {
    .rating-size-product {
      font-size: 20px;
      color:  #ffc13b;
    }
  }
}
.br-theme-fontawesome-stars-o {
  .br-widget {
    a {
      color: #ffa800;
      .br-selected, .br-active:after {
        color: #ffa800;
        font: normal normal normal 14px/1 $font-awesome;
      }
    }
  }
}
// profile
.nav-pills {
  .nav-link, .show {
    &.active,
    > .nav-link {
      background-color: $primary-color;
    }
  }
}
.deactivate-account  {
  margin-top: 30px;
  .btn {
    margin-top: 20px;
  }
}
.account-setting {
  h5 {
    margin-bottom: 20px;
  }
}
.tab2-card {
  ul {
    margin-bottom: 30px;
    border-bottom: 1px solid $white-4;
    li {
      a{
        svg {
          width: 18px;
          vertical-align: middle;
        }
      }
    }
  }
  .nav-tabs {
    .nav-link {
      color: $font-color;
      &.active,
      &:focus,
      &:hover {
        color: $primary-color;
        border-color: $transparent-color;
        border-bottom: 2px solid $primary-color;
      }
    }
    .nav-item {
      &.show {
        .nav-link {
          border-color: $transparent-color;
          border-bottom: 2px solid $primary-color;
        }
      }
    }
  }
}
.profile-details {
  img {
    margin-bottom: 20px;
  }
  .social {
    margin-top: 15px;
    .btn-showcase {
      margin-bottom: -10px;
      .btn {
        padding: 8px 13px;
        margin: 0 3px 20px;
        border-radius: 100%;
        width: 40px;
        height: 40px;
      }
      .btn-fb {
        background-color: #50598e;
        color: $white;
      }
      .btn-google {
        background-color: #c64e40;
        color: $white;
      }
      .btn-twitter {
        background-color: #6fa2d8;
        color: $white;
      }
    }
  }
}
.project-status {
  margin-top: 20px;
  .media {
    margin-top: 20px;
  }
}
.sm-progress-bar{
  height:6px;
}
.profile-table {
  table {
    tbody {
      tr {
        td {
          &:first-child {
            width: 250px;
          }
        }
      }
    }
    th, td {
      border: none;
    }
  }
}
// reports
.report-employee {
  .card-header {
    border-bottom: none !important;
  }
  .flot-chart-container {
    height: 317px;
  }
}
.sales-chart {
  height: 307px;
  svg {
    .ct-series-b {
      .ct-point, .ct-line, .ct-bar, .ct-slice-donut {
        stroke: $danger-color;
      }
    }
  }
}
.expense-chart {
  #area-chart1 {
    height: 307px;
  }
}
// profile
.tab2-card {
  .media {
    align-items: center;
    img {
      margin-right: 20px;
    }
  }
}
//typography//
footer{
  &.blockquote-footer{
    bottom: unset;
  }
}
.bg-black{
  background-color: $black;
}
// datepicker
.datepicker {
  box-shadow: 0 4px 14px rgba($primary-color, 0.15);
}
.datepicker--day-name {
  color: $black;
  font-weight: bold;
}
.datepicker--cell {
  &.-current- {
    color: $black;
    border-radius: 5px;
    font-weight: bold;
    border: 2px solid $primary-color;
  }
  &.-focus- {
    background: $primary-color;
    color: $white;
  }
  &.-selected- {
    background: $primary-color;
  }
}
// Media
    .dropzone
    {
        margin-right: auto;
        margin-left: auto;
        padding: 50px;
        border: 2px dashed $primary-color;
        border-radius: 15px;
        min-height: 150px;
    }

    .dropzone .dz-preview .dz-image {
        border-radius: 5px;
        border: none;
    }
    .dropzone  i {
        font-size: 50px;
        color: $primary-color;
        text-align: center;
    }
    .card{
        .card-body {
            z-index: 0;
        }
    }

//tabs
.tab-coupon {
  margin-bottom: 30px;
}
.needs-validation {
  .permission-block {
    .attribute-blocks {
      padding-left: 15px;
      .row {
        padding-left: 20px;
      }
      + .attribute-blocks {
        h5 {
          margin-top: 30px;
        }
      }
      h6 {
        border-bottom: 1px solid $light-semi-gray;
        margin-bottom: 15px;
        padding-bottom: 5px;
      }
    }
  }
  h4 {
    font-weight: 600;
    margin-bottom: 20px;
  }
  input {
    font-size: 14px;
  }
  .input-group-append {
    .btn.calendar {
      border-radius: 0 3px 3px 0;
      height: 100%;
    }
  }
  .form-group {
    align-items: center;
    margin-bottom: 30px;
    label {
      margin-bottom: 0;
      span {
        color: red;
      }
    }
    .checkbox {
      padding-left: 0;
    }
  }
  .editor-label {
    align-items: end;
  }
  .editor-space {
    padding: 0;
  }
  .checkbox {
    input {
      opacity: 0;
    }
    label {
      display: inline-block;
      position: relative;
      padding-left: 16px;
      cursor: pointer;
      margin-top: 10px;
      margin-bottom: 10px;
      &::before {
        content: "";
        display: inline-block;
        position: absolute;
        width: 19px;
        height: 19px;
        left: 0;
        margin-left: -16px;
        border: 1px solid #e8ebf2;
        border-radius: 3px;
        background-color: #fff;
        -webkit-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
        transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
      }
    }
    input[type="checkbox"]:checked+label::before {
      font-family: $font-ICO;
      content: "\efab";
      text-align: center;
      line-height: 1;
      font-size: 18px;
    }
  }
  .checkbox-primary {
    input[type="checkbox"]:checked+label::before {
      border-color: $primary-color;
      color: $primary-color;
    }
  }
  .radio-animated {
    label {
      margin-right: 20px;
    }
  }
  textarea {
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
  }
}
// datatable
.transcation-datatable {
  table {
    th,td {
      &:nth-child(2) {
        text-align: center !important;
      }
    }
  }
}
.badge-warning {
  color: $white;
}
div{
  &.dataTables_wrapper {
    div{
      &.dataTables_paginate{
        margin-top: 25px;
      }
    }
  }
}
.dataTables_wrapper {
  .dataTables_length {
    margin-bottom: 30px;
    label {
      select {
        border-color: $light-semi-gray;
      }
    }
  }
  .dataTables_filter {
    input[type="search"] {
      border: 1px solid $light-semi-gray;
      padding: 0 15px;
      margin-left: 10px;
      height: 37px;
      border-radius: 0;
    }
  }
  .dataTables_paginate {
    margin-left: 15px !important;
    border: 1px solid #f6f7fb;
    border-radius: 0.25rem;
    padding-top: 0;
    .paginate_button {
      &.current, &:active {
        background: $primary-color;
        color: $white !important;
        border: 1px solid $primary-color;
        box-shadow: none;
      }
      &:hover {
        border: 1px solid $primary-color;
        color: $dark-color !important;
        background: transparent !important;
      }
    }
  }
  table {
    &.dataTable {
      border: 1px solid #ebf1ff;
      width: 100%;
      overflow-x: auto;
      &.row-border,&.display{
        tbody{
          th,td{
            border-top: 1px solid #ebf1ff !important;
          }
        }
      }
      tbody{
        tr{
          background-color: rgba(241, 244, 251, 0.5);
          &:hover{
            background-color: rgba(241, 244, 251, 0.7);
          }
          .sorting_1{
            background-color: rgba(241, 244, 251, 0.2) !important;
          }
          &.odd{
            background-color: $white;
          }
        }
      }
      .vendor-list {
        align-items: center;
        img {
          margin-right: 20px;
          height: 40px;
          width: auto !important;
        }
      }
      td {
        padding: 0.75rem !important;
      }
      th{
        background-color: #f1f4fb !important;
        padding: 24px 0.75rem!important;
      }
      thead {
        .sorting, .sorting_asc, .sorting_desc, .sorting_asc_disabled, .sorting_desc_disabled {
          &:before,
          &:after {
            bottom: 25px;
          }
        }
      }
    }
  }
}
.category-table {
  .table-responsive {
    width: 100%;
  }
  table {
    border-color: #e8efff;
    tr {
      td, th {
        border-color: #e8efff;
      }
    }
    tr {
      .fa-edit {
        color: #22af47;
      }
      .fa-trash-o {
        color: #ff5370;
      }
      .fa-circle {
        font-size: 14px;
      }
      i {
        font-size: 20px;
      }
      td, th {
        text-align: center;
        vertical-align: baseline;
        &:nth-child(2) {
          text-align: left;
        }
      }
    }
  }
}
.table-striped {
  > tbody {
    > tr {
      &:nth-of-type(odd) > * {
        --bs-table-accent-bg: rgba(241, 244, 251, 0.5);
      }
    }
  }
}
.order-table {
  table {
    tr {
      td, th {
        &:nth-child(3) {
          text-align: center;
        }
      }
    }
  }
}
.product-list, .report-table {
  table {
    tr {
      td, th {
        text-align: left !important;
        &:last-child {
          text-align: center !important;
        }
      }
    }
    .jsgrid-filter-row {
      display: none !important;
    }
    .jsgrid-cell {
      padding: 0.75rem;
    }
  }
}
.jsgrid-pager {
  padding: 2em 0 0;
}
.jsgrid-grid-header{
  border:1px solid #e8efff;
}
.user-list {
  img {
    border-radius: 100%;
  }
}
/**=====================
  Admin CSS Ends
==========================**/


/**=====================
      Button CSS start
==========================**/
@mixin btn-gradien($deg,$leftcolor,$leftcolor-per,$rightcolor,$rightcolor-per,$font-color){
  background-image: linear-gradient(to right,$leftcolor 0%,$rightcolor 51%,$leftcolor 100%);
  border:none;
  color:$font-color;
  background-size: auto 200%;
  transition: all 0.3s ease;
}
@mixin btn-squre($border-radius){
  border-radius:$border-radius+px;
}
@mixin btn-pill($border-radius){
  border-radius:$border-radius+px;
}
@mixin btn-pill-first($radius){
  border-radius: $radius+px 0 0 $radius+px;
}
@mixin btn-pill-last($radius){
  border-radius: 0 $radius+px $radius+px 0;
}

.btn{
  font-size: $btn-font-size;
  padding: $btn-padding;
  border-radius: 5px;
  letter-spacing: 1px;
  line-height: 20px;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700;
  transition: .3s ease-in-out;
}
.btn-lg{
  font-size: $btn-lg-font-size;
}
.btn-sm{
  font-size: $btn-sm-font-size;
}
.btn-xs {
  padding: $btn-xs-padding;
  font-size: $btn-xs-font-size;
}
.large-btn{
  .btn{
    margin-right: 5px;
    margin-bottom: 15px;
  }
  margin-bottom: -20px;
}
// outline buttons//
@each $btn-name, $btn-color in (primary, $primary-color),
        (secondary, $secondary-color) ,
        (success, $success-color),
        (danger, $danger-color),
        (info, $info-color),
        (light, $light-color),
        (dark, $dark-color),
        (warning, $warning-color) {
  .btn-air-#{$btn-name}{
    box-shadow: 0 5px 10px 2px rgba(88,103,221,.19)!important;
    &:hover,&:active,&:not([disabled]):not(.disabled):active{
      background-color: darken( $btn-color, 10% );
      border-color: darken( $btn-color, 10% );
    }
  }
}


// forms //
@each $btn-name, $btn-color in (primary, $primary-color),
        (secondary, $secondary-color) ,
        (success, $success-color),
        (danger, $danger-color),
        (info, $info-color),
        (light, $light-color),
        (dark, $dark-color),
        (warning, $warning-color) {
  .input-air-#{$btn-name}{
    box-shadow: 0 3px 5px 1px rgba(88,103,221,.15)!important;
    &:focus{
      border-color:$btn-color;
    }
  }
}

//input background //
@each $btn-name, $btn-color in (primary, $primary-color),
        (secondary, $secondary-color) ,
        (success, $success-color),
        (danger, $danger-color),
        (info, $info-color),
        (light, $light-color),
        (dark, $dark-color),
        (warning, $warning-color) {
  .form-bg-#{$btn-name}{
    background:$btn-color;
    border-color:$btn-color;
    @if($btn-name == light){
      color:$dark-color;
    }
    @else{
      color: $white;
    }
    &:focus{
      border-color:$btn-color;
      background:$btn-color;
      @if($btn-name == light){
        color:$dark-color;
      }
      @else{
        color: $white;
      }
      &:active{
        border-color:$btn-color;
        background:$btn-color;
        @if($btn-name == light){
          color:$dark-color;
        }
        @else{
          color: $white;
        }
      }
    }
  }
}

.btn-warning{
  color: $white;
  &:hover,
  &.disabled{
    color: $white;
  }
}
.btn-primary-gradien{
  @include btn-gradien(60,lighten($primary-color,8%),0%,darken($primary-color,8%),100%,$white);
  &:focus,&:active,&.active{
    transition: 1.5s;
    background-position: right center;
    background-image: linear-gradient(to right, lighten($primary-color,8%),0%,darken($primary-color,8%),100%,$white) !important;

  }
}
.btn-secondary-gradien{
  @include btn-gradien(60,lighten($secondary-color,8%),0%,darken($secondary-color,8%),100%,$white);
  &:hover,&:focus,&:active,&.active,&.hover{
    transition: 1.5s;
    background-position: right center;
    background-image: linear-gradient(to right, lighten($secondary-color,8%),0%,darken($secondary-color,8%),100%,$white) !important;
  }
}
.btn-success-gradien{
  @include btn-gradien(60,lighten($success-color,8%),0%,darken($success-color,8%),100%,$white);
  &:hover,&:focus,&:active,&.active,&.hover{
    transition: 1.5s;
    background-position: right center;
    background-image: linear-gradient(to right, lighten($success-color,8%),0%,darken($success-color,8%),100%,$white) !important;
  }
}
.btn-danger-gradien{
  @include btn-gradien(60,lighten($danger-color,8%),0%,darken($danger-color,8%),100%,$white);
  &:hover,&:focus,&:active,&.active,&.hover{
    transition: 1.5s;
    background-position: right center;
    background-image: linear-gradient(to right, lighten($danger-color,8%),0%,darken($danger-color,8%),100%,$white) !important;
  }
}
.btn-warning-gradien{
  @include btn-gradien(60,lighten($warning-color,8%),0%,darken($warning-color,8%),100%,$white);
  &:hover,&:focus,&:active,&.active,&.hover{
    transition: 1.5s;
    background-position: right center;
    background-image: linear-gradient(to right, lighten($warning-color,8%),0%,darken($warning-color,8%),100%,$white) !important;
  }
}
.btn-info-gradien{
  @include btn-gradien(60,lighten($info-color,8%),0%,darken($info-color,8%),100%,$white);
  &:hover,&:focus,&:active,&.active,&.hover{
    transition: 1.5s;
    background-position: right center;
    background-image: linear-gradient(to right, lighten($info-color,8%),0%,darken($info-color,8%),100%,$white) !important;
  }
}
.btn-light-gradien{
  @include btn-gradien(60,lighten($light-color,8%),0%,darken($light-color,8%),100%,$white);
  &:hover,&:focus,&:active,&.active,&.hover{
    transition: 1.5s;
    background-position: right center;
    background-image: linear-gradient(to right, lighten($light-color,8%),0%,darken($light-color,8%),100%,$white) !important;
  }
}
.btn-dark-gradien{
  @include btn-gradien(60,lighten($dark-color,8%),0%,darken($dark-color,8%),100%,$white);
  &:hover,&:focus,&:active,&.active,&.hover{
    transition: 1.5s;
    background-position: right center;
    background-image: linear-gradient(to right, lighten($dark-color,8%),0%,darken($dark-color,8%),100%,$white) !important;
  }
}
[class*='-gradien']{
  &:hover{
    background-size: 50% 100%;
    transition: all 0.3s ease;
    color: $white;
  }
}

/**=====================
 Button CSS end
==========================**/

/**=====================
      Card CSS Start
==========================**/

.height-equal {
  height: calc(100% - 30px);
}

.card{
  margin-bottom: $card-margin-bottom;
  border: $card-border-width;
  transition: all 0.3s ease;
  letter-spacing: 0.5px;
  border-radius: $card-border-radious;
  box-shadow: $card-box-shadow;
  &.card-load {
    .card-loader {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      background-color: rgba(255, 255, 255, 0.7);
      z-index: 8;
      align-items: center;
      justify-content: center;
      i{
        margin: 0 auto;
        color: $primary-color;
        font-size: 20px;
      }
    }
  }
  &.full-card {
    position: fixed;
    top: 80px;
    z-index: 99999;
    box-shadow: none;
    right: 0;
    border-radius: 0;
    border: 1px solid $light-semi-gray;
    width: calc(100vw - 272px);
    height: calc(100vh - 80px);
    .card-body {
      overflow: auto;
    }
  }
  .card-header{
    background-color:  $card-header-bg-color;
    border-bottom: none;
    padding: $card-padding;
    border-bottom: 1px solid $card-border-color;
    border-top-left-radius: $card-border-radious;
    border-top-right-radius: $card-border-radious;
    .card-header-right{
      border-radius: 0 0 0 7px;
      right: 20px;
      top: 25px;
      display: inline-block;
      float: right;
      padding: 7px 0;
      position: absolute;
      .card-option{
        text-align: right;
        width: 35px;
        height: 20px;
        overflow: hidden;
        -webkit-transition: 0.3s ease-in-out;
        transition: 0.3s ease-in-out;
        li{
          display: inline-block;
        }
      }
      i{
        margin: 0 5px;
        cursor: pointer;
        color: $dark-color;
        line-height: 20px;
        &.icofont-refresh{
          font-size: 13px;
        }
      }
    }
    h5{
      font-size:$card-header-font-size;
      margin-bottom: 0;
      text-transform: $card-header-font-transform;
      font-weight: $card-header-font-weight;
      line-height:24px;
    }
    > span{
      font-size: $card-header-span-size;
      color: $card-header-span-color;
      margin-top: 5px;
      display: block;
      letter-spacing: 1px;
    }
  }
  .card-body{
    padding: $card-padding;
    background-color: $card-body-bg-color;
    p{
      &:last-child{
        margin-bottom: 0;
      }
    }
  }
  .sub-title{
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding-bottom: 5px;
    margin-bottom: 8px;
    font-size: 18px;
  }
  .card-footer{
    background-color: $card-footer-bg-color;
    border-top: 1px solid $card-border-color;
    padding: $card-padding;
    border-bottom-left-radius: $card-border-radious;
    border-bottom-right-radius: $card-border-radious;
  }
}
.card-header{
  .nav-material{
    margin-bottom: -13px;
  }
}
/**=====================
     Card CSS End
==========================**/

/**=====================
      Footer CSS Start
==========================**/
footer{
  background-color: $footer_bg_color;
  border-top: 1px solid $footer_top_bgr_color;
  padding: 15px;
  bottom: 0;
  left: 0;
  a{
    font-weight: 600;
  }
  &.footer-starter-kit{
    bottom: -52px;
  }
}
.blockquote-footer{
  margin-left: 0 !important;
  width: 885px !important;
}
.page-wrapper{
  .page-body-wrapper{
    footer{
      margin-left: 255px;
      transition: all 0.3s ease;
      bottom: 0;
      z-index: 8;
      p{
        color: rgb(165, 165, 165);
        i{
          color: $danger-color;
          margin-left: 5px;
        }
      }
    }
  }

}
/**=====================
      Footer CSS Ends
==========================**/

/**=====================
      General CSS Start
==========================**/
//Custom css Start
body {
  background-color: $body-bg-color;
  font-size: $body-font-size;
  overflow-x: hidden;
  font-family: $font-work-sans, $font-serif;
  color: $theme-body-font-color;
}
ul {
  padding-left: $ul-padding-left;
  list-style-type: none;
  margin-bottom: 0;
  padding-right: $ul-padding-right;
}
* a {
  color: $primary-color;
}
*.btn:focus{
  box-shadow : $btn-focus-box-shadow !important;
}
p {
  font-size: $paragraph-font-size;
  line-height: $paragraph-line-height;
  letter-spacing: $paragraph-letter-spacing;
}
code{
  color: $code-tag-color !important;
  background-color: $code-tag-bg-color;
  padding: $code-tag-padding;
  margin: $code-tag-margin;
  border-radius: $code-tag-border-radious;
}
blockquote{
  border-left: $blockquote-border;
  padding: $blockquote-padding;
}
blockquote{
  &.text-center{
    border: none;
    padding: $blockquote-padding;
  }
}
blockquote{
  &.text-right{
    border-left: none;
    border-right: $blockquote-border;
    padding: $blockquote-padding;
  }
}
:focus{
  outline-color: $all-focus-outline-color;
}
.disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
svg{
  vertical-align: baseline;
}
input {
  &:focus {
    outline-color: $transparent-color;
  }
}
.txt-dark {
  color: $theme-font-color !important;
}
.txt-success {
  color: $success-color !important;
}
.txt-danger {
  color: $danger-color !important;
}
/**=====================
      General CSS Ends
==========================**/

$header-size: 80px;

/**=====================
    Header CSS Start
==========================**/
%common {
  color: $theme-body-font-color;
  border-left-color: $dark-color;
  transition: $sidebar-transition;
}
%for-animated-hover {
  box-shadow: 0 0 2px 2px $light-color;
  transform: translateY(30px);
  opacity: 0;
  visibility: hidden;
  left: 0;
}
%for-animated-hover-box{
  opacity: 1;
  transform: translateY(0px);
  visibility: visible;
  &:before{
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid $white;
    content: "";
    top: -7px;
    position: absolute;
    left: 10px;
    z-index: 2;
  }
  &:after{
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid $dark-gray;
    content: "";
    top: -7px;
    position: absolute;
    left: 10px;
    z-index: 1;
  }
}
%sidebar-icon {
  display: block;
  transform: rotateX(0deg);
  z-index: 1;
  background-color: $white;
  color: $dark-gray;
  font-size: 14px;
  opacity: 1;
  margin-top: -70px;
  padding: 10px 0;
  text-align: left;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.13);
  position: absolute;
  width: 245px;
  margin-left: 115px;
}
/*======= Page Header css Start ======= */
.page-wrapper{
  .page-main-header{
    background-color: $main-header-bg-color;
    height: $header-size;
    position: $main-header-position;
    top: $main-header-top;
    z-index: $main-header-z-index;
    box-shadow: $main-header-shadow;
    width: calc(100% - #{$sidebar-width});
    display: flex;
    align-items: center;
    margin-left: $sidebar-width;
    transition: $sidebar-transition;
    &.open{
      margin-left: 0;
      width: 100%;
      transition: $sidebar-transition;
    }
  }
  .page-body-wrapper{
    .page-body{
      min-height: calc(100vh - #{$header-size});
      margin-top: $header-size;
      padding: $page-body-padding;
      position: relative;
      background-color: $light-color;
    }
    .page-header{
      padding-top: $page-header-padding;
      padding-bottom: $page-header-padding;
      .row{
        align-items: center;
        .page-header-left {
          display: flex;
          align-items: center;
        }
        h3{
          font-size: $page-title-font-size;
          margin-bottom: $page-title-margin-bottom;
          font-weight: $page-title-font-weight;
          text-transform: $page-title-text-tranform;
          font-family: $font-nunito;
          small {
            display: block;
            font-size: 12px;
            margin-top: 7px;
            letter-spacing: 1px;
            text-transform: capitalize;
            color: $theme-body-sub-title-color;
          }
        }
      }
      .breadcrumb{
        background-color: $transparent-color;
        padding: 0;
        margin-bottom: 0;
        .breadcrumb-item{
          font-family: $font-nunito;
          &+.breadcrumb-item{
            &:before{
              content: $breadcrumb-content;
            }
          }
          a{
            svg{
              width: $breadcrumb-size;
              height: $breadcrumb-size;
              vertical-align: $breadcrumb-svg-icon-align;
              margin-top: 2px;
            }
          }
        }
      }

    }
  }
}
/*======= Page Header css ends  ======= */


/**======Main Header css Start ======**/
.offcanvas {
  .page-wrapper {
    .page-body-wrapper {
      .page-body {
        position: relative;
        &:before {
          position: fixed;
          content: '';
          background-color: rgba(0,0,0,0.5);
          height: 100%;
          width: 100%;
          z-index: 10;
          right: 0;
          left: 255px;
        }
      }
    }
  }
}
.page-main-header{
  max-width: 100vw;

  .main-header-right{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 22px;
    margin: 0;
    svg{
      color: $main-header-right-toggle-color;
      line, polyline {
        color: $main-header-right-toggle-color;
      }
    }
    .nav-left{
      i{
        margin-right: 20px;
      }
      input:focus{
        outline: 0 !important;
      }
    }
    .nav-right{
      text-align: right;
      padding-left: $main-header-right-nav-right;
      ul{
        li{
          .media{
            .dotted-animation{
              position: relative;
              right: -3px;
              top:10px;
              .animate-circle{
                position: absolute;
                top: -20px;
                right: -4px;
                height: 25px;
                width: 25px;
                z-index: 10;
                border: 5px solid $primary-color;
                border-radius: 70px;
                animation: heartbit 1s ease-out;
                animation-iteration-count: infinite;
              }
              .main-circle{
                width: 6px;
                height: 6px;
                border-radius: 30px;
                background-color: $primary-color;
                position: absolute;
                right: 6px;
                top: -10px;
              }
            }
          }
          svg{
            margin-top: $main-header-right-nav-icon-margin-top;
            width: $main-header-right-nav-icon-size;
            height: $main-header-right-nav-icon-size;
            path{
              color: $main-header-right-nav-icon-color;
            }
          }
          .dot{
            width: 3px;
            height: 3px;
            border-radius: 30px;
            background-color: $main-header-right-nav-icon-color;
            position: absolute;
            right: 17px;
            bottom: 6px;
            animation: blink 1.5s infinite;
          }
          .dot-chat{
            right: 4px;
            bottom: -11px;
          }
        }
      }
      .notification {
        position: absolute;
        top: 21px;
        right: -1px;
        font-size: 9px;
        animation : blink 1.5s infinite;
        @keyframes blink {
          0% {
            opacity: 1;
          }

          70% {
            opacity: 1;
          }

          80% {
            opacity: 0;
          }

          100% {
            opacity: 0;
          }
        }
      }
      .icon-user{
        font-size: 16px;
      }
      @keyframes heartbit{
        0%{transform:scale(0);
          opacity:0
        }
        25%
        {transform:scale(0.1);
          opacity:.1
        }
        50%
        {
          transform:scale(0.5);
          opacity:.3
        }
        75%{
          transform:scale(0.8);
          opacity:.5
        }
        100%
        {
          transform:scale(1);
          opacity:0
        }
      }
      >ul{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        > li{
          position: relative;
          border-left: 1px solid $light-semi-gray;
          padding: 0 20px;
          a {
            text-decoration: none;
          }
          &:first-child{
            width: 45%;
            border-left: none;
            .search-form{
              .form-group{
                width: 100%;
                position: relative;
                &:focus{
                  outline-color: transparent;
                }
                input{
                  &:focus{
                    outline-color: transparent;
                  }
                }
                &:before{
                  position: absolute;
                  content: "";
                  width: 1px;
                  height: 25px;
                  background: $light-gray;
                  left: 51px;
                  top: 9px;
                }
                &:after{
                  position: absolute;
                  content: "\f002";
                  font-family: $font-awesome;
                  top: 11px;
                  left: 22px;
                  color: #8e8e8e;
                }
              }
            }
          }
          &:last-child{
            border-left: none;
            padding-right: 0;
          }
          &:nth-child(5){
            border-left: none;
            border-right: 1px solid $light-semi-gray;
            padding-left: 0;
          }
          h6{
            margin-top: 4px;
            margin-bottom: 4px;
            color: $primary-color;
            ul{
              left: inherit;
              right: -10px;
              width: 130px;
              &:before,&:after{
                left: inherit;
                right: 10px;
              }
              li{
                display: block;
                a{
                  font-size: 14px;
                  color: $dark-color;
                  i{
                    margin-left: 10px;
                    font-size: 13px;
                    color: $dark-color;
                  }
                }
              }
            }
          }
        }
        .flag-icon{
          font-size: 16px;
        }
      }
      .notification-dropdown{
        top: 57px;
        .txt-success {
          svg {
             polyline {
               color: $success-color;
             }
          }
        }
        .txt-danger {
          svg {
            circle {
              color: $danger-color;
            }
          }
        }
      }
      .language-dropdown{
        width: 160px;
        text-align: left;
        top: 57px;
        li{
          padding-top: 10px;
          a{
            color: $dark-color;
            i{
              margin-right: 10px;
            }
          }
          &:first-child{
            padding-top: 0;
          }
        }
      }
      .profile-dropdown{
        right: -10px;
        left: inherit;
        width: 150px;
        top: 63px;
        &:before,&:after{
          left: inherit;
          right: 10px;
        }
        li{
          display: block;
          text-align: left;
          padding-top: 10px;
          &:nth-child(3){
            padding-bottom: 10px;
          }
          &:nth-child(4){
            border-top: 1px solid $light-semi-gray;
          }
          &:first-child{
            padding-top: 0;
          }
          &:hover{
            a{
              color: $primary-color;
              transition: $sidebar-transition;
              svg{
                color: $primary-color !important;
                path, line, polyline {
                  color: $primary-color !important;
                }
              }
            }
          }
          a{
            color: $theme-body-font-color;
            transition: $sidebar-transition;
            display: flex;
            align-items: center;
            svg{
              margin-right: 10px;
              color: $theme-body-font-color;
              margin-bottom: -3px;
              polyline {
                color: $theme-body-font-color;
              }
            }
          }
        }
      }
    }
    li{
      display: inline-block;
      position: relative;

    }
  }
}
.nav-menus{
  .notification-badge {
    position: absolute;
    right: 10px;
    top: 1px;
    padding: 4px 7px;
  }
  .onhover-dropdown{
    cursor: pointer;
    position: relative;
    &:before{
      display: none;
    }
    &:hover{
      .onhover-show-div{
        @extend  %for-animated-hover-box;
      }
    }
  }
}

ul.notification-dropdown.onhover-show-div{
  width: 330px;
  right: -18px;
  left: initial;
  &:before,&:after{
    left: inherit !important;
    right: 35px !important;
  }
  li{
    display: block;
    padding: 12px 20px;
    border-bottom: 1px solid $light-color;
    text-align: left;
    h6{
      small{
        padding-top: 5px;
        color: $dark-gray;
        font-size: 12px;
      }
    }
    span{
      svg{
        margin-top: 0 !important;
        margin-right: 10px;
        vertical-align: text-top;
      }
      .shopping-color{
        path,line{
          color: $primary-color;
        }
      }
      .download-color{
        path,line{
          color: $success-color;
        }
      }
      .alert-color{
        path,line{
          color: $danger-color;
        }
      }
    }
    p{
      margin-left: 30px;
    }
    + li{
      &:hover{
        background-color: $light-color;
      }
    }
  }
}
.onhover-show-div {
  top: $header-size;
  position: absolute;
  z-index: 8;
  background-color: $white;
  transition: all linear 0.3s;
  @extend  %for-animated-hover;
  li{
    a{
      svg{
        margin-top: 0 !important;
        path, line{
          color: $theme-body-font-color !important;
        }
      }
    }
  }
}
/**======Main Header css Ends ======**/

.nav-menus{
  .search-form{
    input {
      border: 1px solid #eff0f1;
      padding: 10px 10px 10px 70px;
      border-radius: 50px;
      background-color: $light-color;
    }
  }
}

/**=====================
    Sidebar CSS Start
==========================**/
.page-wrapper{
  .page-body-wrapper{
    .sidebar{
      height: calc(100vh - #{$header-size});
      overflow: auto;
      box-shadow: $sidebar-shadow;
    }
    .page-sidebar{
      width: $sidebar-width;
      position: $sidebar-position;
      background: $sidebar-background-color;
      top: 0;
      height: calc(100vh);
      z-index: $sidebar-z-index;
      transition: $sidebar-transition;
      .main-header-left{
        display: inline-flex;
        width: 100%;
        height: $header-size;
        padding: $main-header-padding;
        align-items: center;
        background-color: $main-header-left-bg-color;
        z-index: $main-header-z-index;
        box-shadow: -3px 1px 3px 1px rgba(68, 102, 242, 0.1);
        .logo-wrapper{
          padding-left: 10px;
          height: 100%;
          display: flex;
          align-items: center;
        }
      }
      .sidebar-user{
        padding: $sidebar-user-section-padding;
        box-shadow: $sidebar-user-section-shadow;
        position: relative;
        h6{
          color: $sidebar-profile-name-txt-color;
          text-transform: $sidebar-profile-name-txt-transfer;
          font-weight: $sidebar-profile-name-txt-weight;
          letter-spacing: $sidebar-profile-name-letter-specing;
          margin-bottom: $sidebar-profile-name-marging;
        }
        p{
          text-transform: $sidebar-profile-name-txt-transfer;
          font-weight: $sidebar-profile-name-txt-weight;
          font-size: $sidebar-profile-sub-title-font-size;
          margin-bottom: $sidebar-profile-sub-title-margin;
        }
        img{
          box-shadow: $sidebar-profile-img-shadow;
        }
      }
      .sidebar-menu {
        list-style: $sidebar-menu-list-style;
        margin: $sidebar-menu-list-margin;
        padding: $sidebar-menu-padding;
        .sidebar-header{
          font-size: $sidebar-font-size;
          letter-spacing: $sidebar-letter-specing;
          padding-bottom: $sidebar-padding-bottom;
          padding-top: $sidebar-padding-top;
          text-transform: $sidebar-txt-transform;
          font-weight: $sidebar-font-weight;
          color: $sidebar-font-color;
          svg{
            width: $sidebar-icon-size;
            height: $sidebar-icon-size;
            margin-right: $sidebar-icon-margin;
            stroke-width: $sidebar-icon-stroke-width;
            vertical-align: text-bottom;
          }
        }
        .sidebar-submenu{
          &.menu-open{
            li{
              &.active{
                .fa-angle-down{
                  &:before{
                    content: "\f104";
                    transition: $sidebar-transition;
                  }
                }
              }
            }
          }
        }
        >
        li {
          > a {
            display: block;
            transition: $sidebar-transition;
            &.active{
              color: $sidebar-hover-txt-color;
              transition: $sidebar-transition;
            }
            &:hover{
              color: $sidebar-hover-txt-color;
              padding-left: $sidebar-heading-hover-padding;
              transition: $sidebar-transition;
            }
            i{
              margin-right: 7px;
              text-align: right;
              margin-top: 3px;
              font-size: $sidebar-arrow-size;
              &:before{
                content: $sidebar-close-icon;
              }
              & ~i{
                margin-right: 0;
              }
            }
          }
          .label {
            margin-top: 3px;
            margin-right: 5px;
          }
          .badge {
            margin-left: 50px;
            text-transform: capitalize;
          }
          &:hover > a {
            @extend %common;
          }
          &.active > a {
            @extend %common;
          }
        }
        li {
          &.sidebar-header {
            margin-bottom: $sidebar-sub-header-margin;
            padding: $sidebar-sub-header-padding;
            color: $sidebar-font-color;
            font-weight: $sidebar-font-weight;
            transition: $sidebar-transition;
          }
          > a {
            > .fa-angle-down {
              width: auto;
              height: auto;
              padding: 0;
              margin-right: 10px;
              margin-top: 10px;
              transform: rotate(-90deg);
              transition: $sidebar-transition;
            }
          }
          &.active {
            > {
              a {
                > .fa-angle-right {
                  &:before{
                    content: $sidebar-open-icon;
                    font-family: $sidebar-icon-font-family;
                    transition: $sidebar-transition;
                  }
                }
              }
            }
            > .sidebar-submenu {
              display: block;
              margin-left: 15px;
              a {
                i.pull-right {
                  margin-top: 10px;
                }
              }
            }
          }
        }
        a {
          text-decoration: none;
        }
        .sidebar-submenu {
          display: none;
          animation: 0.3s alternate sidebar-menu-open forwards;
          list-style: none;
          .sidebar-submenu {
            padding-top: 0 !important;
          }
          > li {
            > a {
              padding-bottom: 7px;
              padding-top: 7px;
              font-size: 14px;
              color: $dark-gray;
              transition: $sidebar-transition;
              text-transform: $sidebar-text-transform;
              position: relative;
              padding-left: 10px;
              line-height: 2.5;
              letter-spacing: 0.7px;
              > {
                .fa-circle {
                  width: 12px;
                  font-size: 4px;
                  position: absolute;
                  left: 0;
                  top: 13px;
                }
                i {
                  width: auto;
                }
                .fa-angle-down {
                  width: auto;
                }
              }
              &:hover {
                color: $primary-color;
                transition: $sidebar-transition;
              }
              &.active{
                color: $primary-color;
              }
            }
            &.active > a {
              color: $theme-body-font-color;
              &.active {
                color: $primary-color;
              }
            }
          }
        }
      }
      ~ .page-body {
        margin-left: $sidebar-width;
        transition: $sidebar-transition;
      }

      &.open{
        display: block;
        margin-left: calc(-#{$sidebar-width + 5px});
        ~ .page-body {
          margin-left: 0;
          transition: $sidebar-transition;
        }
        ~ footer {
          margin-left: 0;
          padding-right: 15px;
        }
        ~ .footer-fix {
          width: calc(100% - 0px);
        }
      }
    }
    .sidebar-close{
      .page-sidebar{
        transition: $sidebar-transition;
        transform: translate(-$sidebar-width);
      }
      .page-body{
        transition: $sidebar-transition;
        margin-left: 0 !important;
      }
    }

  }
}
.right-sidebar {
  top: 81px;
  right: -285px;
  height: 100%;
  position: fixed;
  width: 285px;
  z-index: 9;
  background-color: #fff;
  transition: .5s;
  box-shadow: 0 0 9px rgba(191, 191, 191, 0.36);
  &.show{
    right: 0;
    transition: $sidebar-transition;
  }
  .modal-header{
    .modal-title{
      padding-top: 2px;
    }
  }
  .friend-list-search {
    position: relative;
    background-color: $light-color ;
    padding: 20px;
    input {
      color: $dark-gray;
      width: 100%;
      background-color: $white;
      border: 1px solid $light-color ;
      padding: 10px 15px;
      border-radius: 25px;
      letter-spacing: 1px;
    }
    .fa {
      position: absolute;
      right: 35px;
      top: 34px;
      font-size: 14px;
      color: $light-gray ;
    }
  }
  .chat-box{
    .people-list{
      ul {
        padding-top: 20px;
        li{
          position: relative;
        }
      }
    }
  }
  svg{
    width: 16px;
    height: 16px;
    margin-top: 5px;
  }
}

/**=====================
    Sidebar CSS Ends
==========================**/


/**=====================
      Generic CSS Start
==========================**/

/*====== Padding css starts ======*/
$i:0;
@while $i<=50 {
  .p-#{$i} {
    padding:#{$i}px;
  }
  $i:$i+5;
}
/*====== Padding css ends ======*/

/*====== Padding-left css starts ======*/
$i:0;
@while $i<=50 {
  .p-l-#{$i} {
    padding-left:#{$i}px;
  }
  $i:$i+5;

}
/*====== Padding-left css ends ======*/

/*====== Padding-top css starts ======*/
$i:0;
@while $i<=50 {
  .p-t-#{$i} {
    padding-top:#{$i}px !important;
  }
  $i:$i+5;
}
/*====== Padding-top css ends ======*/


/*====== Padding-bottom css starts ======*/
$i:0;
@while $i<=50 {
  .p-b-#{$i} {
    padding-bottom:#{$i}px !important;
  }
  $i:$i+5;
}
/*====== Padding-bottom css ends ======*/

/*====== Padding-right css starts ======*/
$i:0;
@while $i<=50 {
  .p-r-#{$i} {
    padding-right:#{$i}px;
  }
  $i:$i+5;
}
/*====== Padding-right css ends ======*/


/*====== Margin css starts ======*/
$i:0;
@while $i<=50 {
  .m-#{$i} {
    margin:#{$i}px !important;
  }
  $i:$i+5;
}
/*====== Margin css ends ======*/

/*====== Margin-top css starts ======*/
$i:0;
@while $i<=50 {
  .m-t-#{$i} {
    margin-top:#{$i}px !important;
  }
  $i:$i+5;
}
/*====== Margin-top css ends ======*/

/*====== Margin-Bottom css starts ======*/
$i:0;
@while $i<=50 {
  .m-b-#{$i} {
    margin-bottom:#{$i}px !important;
  }
  $i:$i+5;
}
/*====== Margin-Bottom css ends ======*/

/*====== Margin-left css starts ======*/
$i:0;
@while $i<=50 {
  .m-l-#{$i} {
    margin-left:#{$i}px !important;
  }
  $i:$i+5;
}
/*====== Margin-left css ends ======*/

/*====== Margin-right css starts ======*/
$i:0;
@while $i<=50 {
  .m-r-#{$i} {
    margin-right:#{$i}px;
  }
  $i:$i+5;
}
/*====== Margin-right css ends ======*/

/*====== Border-radius css starts ======*/
$i:0;
@while $i<=10 {
  .b-r-#{$i} {
    border-radius:#{$i}px !important;
  }
  $i:$i+1;
}
/*====== Border-radius css ends ======*/


/*====== Font-size css starts ======*/
$i:12;
@while $i<=100 {
  .f-#{$i} {
    font-size:#{$i}px;
  }
  $i:$i+2;
}
/*====== Font-size css ends ======*/


/*====== Font-weight css starts ======*/
$i:100,
300,
400,
600,
700,
900;
@each $val in $i {
  .f-w-#{$val} {
    font-weight: $val;
  }
}
/*====== Font-weight css ends ======*/


/*====== Font-style css starts ======*/
$i:normal,
italic,
oblique,
initial,
inherit;
@each $val in $i {
  .f-s-#{$val} {
    font-style: $val;
  }
}
/*====== Font-style css ends ======*/


/*====== Text-Decoration css starts ======*/

$i:overline,
line-through,
underline,
dashed,
blink,
dotted,
initial,
none,
solid,
wavy,
inherit,
double;

@each $val in $i {
  .text-#{$val} {
    text-decoration: $val;
  }
}

/*====== Text-Decoration css ends ======*/


/*====== Vertical-Align css starts ======*/
$i:baseline,
sub,
super,
top,
text-top,
middle,
bottom,
text-bottom,
initial,
inherit;
@each $val in $i {
  .#{$val} {
    vertical-align: $val;
  }
}
/*====== Vertical-Align css ends ======*/


/*====== Position css starts ======*/

$i:static,
absolute,
fixed,
relative,
initial,
inherit;
@each $val in $i {
  .p-#{$val} {
    position: $val;
  }
}
/*====== Position css ends ======*/

/*====== Float css starts ======*/
$i:left,
right,
none;
@each $val in $i {
  .f-#{$val} {
    float: $val;
  }
}
/*====== Float css ends ======*/


/*====== Overflow css starts ======*/
$i:hidden,
visible,
auto;
@each $val in $i {
  .o-#{$val} {
    overflow: $val;
  }
}
/*====== Overflow css ends ======*/


/*====== Image-sizes css starts ======*/
$i:10;
@while $i<=100 {
  .img-#{$i} {
    width:#{$i}px !important;
  }
  $i:$i+10;
}
/*====== Image-sizes css ends ======*/

/*======= Text css starts ===========*/
$text-name:
        primary,
        secondary,
        success,
        danger,
        info,
        light,
        dark,
        warning;
$text-color : $primary-color,
$secondary-color,
$success-color,
$danger-color,
$info-color,
$light-color,
$dark-color,
$warning-color;
@each $var in $text-name {
  $i: index($text-name, $var);
  .font-#{$var} {
    color: nth($text-color, $i) !important;
  }
}
/*======= Text css ends ===========*/


/*======= Label-color css starts  ======= */

.label {
  border-radius: 2px;
  color: $white;
  font-size: 12px;
  line-height: 1;
  margin-bottom: 0;
  text-transform: capitalize;
}

$label-name:theme,
primary,
secondary,
success,
danger,
info,
light,
dark,
warning;
$label-color: $primary-color,
$primary-color,
$secondary-color,
$success-color,
$danger-color,
$info-color,
$light-color,
$dark-color,
$warning-color;
@each $var in $label-name {
  $i: index($label-name, $var);
  .label-#{$var} {
    background-color: nth($label-color, $i);
  }
}


/*======= Label-color css ends  ======= */


/*======= Badge-color css starts  ======= */

$badge-name:
        primary,
        secondary,
        success,
        danger,
        info,
        light,
        dark,
        warning;
$badge-color:
        $primary-color,
        $secondary-color,
        $success-color,
        $danger-color,
        $info-color,
        $light-color,
        $dark-color,
        $warning-color;
@each $var in $badge-name {
  $i: index($badge-name, $var);
  .badge-#{$var} {
    background-color: nth($badge-color, $i);
  }
}


/*======= Badge-color css end  ======= */


/*======= Background-color css starts  ======= */

$background-name:
        primary,
        secondary,
        success,
        danger,
        info,
        light,
        dark,
        warning;
$background-color:
        $primary-color,
        $secondary-color,
        $success-color,
        $danger-color,
        $info-color,
        $light-color,
        $dark-color,
        $warning-color;
@each $var in $background-name {
  $i: index($background-name, $var);
  .bg-#{$var} {
    background-color: nth($background-color, $i) !important;
    color: $white;
  }
}


/*======= Background-color css end  ======= */

/*======= Font-color css starts  ======= */

$text-name:primary,
secondary,
success,
danger,
info,
light,
dark,
warning,
google-plus,
twitter,
linkedin,
fb;
$label-color:$primary-color,
$secondary-color,
$success-color,
$danger-color,
$info-color,
$light-color,
$dark-color,
$warning-color;
/*======= Font-color css end  ======= */


/*======= Button-color css starts  ======= */
// btn--color//
@each $btn-name, $btn-color in  (primary, $primary-color),
        (secondary, $secondary-color) ,
        (success, $success-color),
        (danger, $danger-color),
        (info, $info-color),
        (light, $light-color),
        (dark, $dark-color),
        (warning, $warning-color) {
  .btn-#{$btn-name}{
    background-color: $btn-color !important;
    border-color: $btn-color !important;
    &.disabled{
      background-color: $btn-color !important;
      border-color: $btn-color !important;
    }
    &:disabled{
      background-color: $btn-color !important;
      border-color: $btn-color !important;
    }
    &:hover,&:focus,&:active,&.active{
      background-color: darken( $btn-color, 10% ) !important;
      border-color: darken( $btn-color, 10% ) !important;
    }
    &:focus{
      box-shadow: 0 0 0 0.2rem lighten( $btn-color, 30% );
    }
  }
}
/*======= Button-color css ends  ======= */
@each $btn-name, $btn-color in  (primary, $primary-color),
        (secondary, $secondary-color) ,
        (success, $success-color),
        (danger, $danger-color),
        (info, $info-color),
        (light, $light-color),
        (dark, $dark-color),
        (warning, $warning-color) {
  .btn-outline-#{$btn-name}-2x{
    border-width: 2px;
    border-color:$btn-color;
    color: $btn-color;
    background-color: transparent;
    &:hover,&:focus,&:active,&.active{
      color: white;
      background-color: darken( $btn-color, 10%) !important;
      border-color: darken( $btn-color, 10% )!important;
      box-shadow: none;
    }
  }
}

// outline buttons//
@each $btn-name, $btn-color in  (primary, $primary-color),
        (secondary, $secondary-color) ,
        (success, $success-color),
        (danger, $danger-color),
        (info, $info-color),
        (light, $light-color),
        (dark, $dark-color),
        (warning, $warning-color) {
  .btn-outline-#{$btn-name}{
    border-color:$btn-color;
    color: $btn-color;
    background-color: transparent;
    &.disabled{
      color: $btn-color;
    }
    @if($btn-name == "light"){
      color: $dark-color;
    }
    &:hover,&:focus,&:active,&.active{
      color: white;
      background-color: darken( $btn-color, 10%) !important;
      border-color: darken( $btn-color, 10% ) !important;
    }
  }
}

//active button css //

@each $btn-name, $btn-color in  (primary, $primary-color),
        (secondary, $secondary-color) ,
        (success, $success-color),
        (danger, $danger-color),
        (info, $info-color),
        (light, $light-color),
        (dark, $dark-color),
        (warning, $warning-color) {
  .btn-#{$btn-name}:not([disabled]):not(.disabled).active{
    background-color:darken($btn-color,10%);
    border-color:darken($btn-color,10%);
    box-shadow: none !important;
    &:hover,&:focus,&:active,&.active{
      color: white;
      background-color: darken( $btn-color, 10% );
      border-color: darken( $btn-color, 10% );
    }
  }
}

//* active button css end *//

@each $btn-name, $btn-color in  (primary, $primary-color),
        (secondary, $secondary-color) ,
        (success, $success-color),
        (danger, $danger-color),
        (info, $info-color),
        (light, $light-color),
        (dark, $dark-color),
        (warning, $warning-color) {

  .btn-outline-#{$btn-name}-2x:not([disabled]):not(.disabled).active{
    background-color:$btn-color;
    border-color:$btn-color;
    box-shadow: none !important;
    color: white;
    &:hover,&:focus,&:active,&.active{
      color: white;
      box-shadow: none !important;
      background-color: darken( $btn-color, 10% ) !important;
      border-color: darken( $btn-color, 10% ) !important;
    }
  }
}

/*======= Table-Border-Bottom-color css starts  ======= */

$border-name:primary,
secondary,
success,
danger,
info,
light,
dark,
warning;
$border-color:$primary-color,
$secondary-color,
$success-color,
$danger-color,
$info-color,
$light-color,
$dark-color,
$warning-color;
@each $var in $border-name {
  $i: index($border-name, $var);
  table thead .border-bottom-#{$var} th,
  table tbody .border-bottom-#{$var} th,
  table tbody .border-bottom-#{$var} td {
    border-bottom: 1px solid nth($border-color, $i);
  }
}


/*======= Table-Border-Bottom-color css ends  ======= */


/*======= Table styling css starts  ======= */

$table-name:
        primary,
        secondary,
        success,
        danger,
        info,
        light,
        dark,
        warning;
$table-color:$primary-color,
$secondary-color,
$success-color,
$danger-color,
$info-color,
$light-color,
$dark-color,
$warning-color;
@each $var in $table-name {
  $i: index($table-name, $var);
  .table-styling .table-#{$var},
  .table-styling.table-#{$var} {
    background-color: nth($border-color, $i);
    color: $white;
    border: 3px solid nth($border-color, $i);
    thead {
      background-color: darken(nth($border-color, $i), 10%);
      border: 3px solid darken(nth($border-color, $i), 10%);
    }
  }
}


/*======= Table styling css ends  ======= */




/*======= All-Borders-color css starts  ======= */

$b-name:primary,
secondary,
success,
danger,
info,
light,
dark,
warning;
$b-color:$primary-color,
$secondary-color,
$success-color,
$danger-color,
$info-color,
$light-color,
$dark-color,
$warning-color;
@each $var in $b-name {
  $i: index($b-name, $var);
  .b-#{$var} {
    border: 1px solid nth($b-color, $i) !important;
  }
  .b-t-#{$var} {
    border-top: 1px solid nth($b-color, $i) !important;
  }
  .b-b-#{$var} {
    border-bottom: 1px solid nth($b-color, $i) !important;
  }
  .b-l-#{$var} {
    border-left: 1px solid nth($b-color, $i) !important;
  }
  .b-r-#{$var} {
    border-right: 1px solid nth($b-color, $i) !important;
  }
}


/*======= All-Borders-color css ends  ======= */


/*====== Border width css starts ======*/
$i:1;
@while $i<=10 {
  .border-#{$i} {
    border-width:#{$i}px !important;
  }
  $i:$i+1;
}
/*====== Border width css ends ======*/

.opacity-0{
  opacity: 0;
}
.shadow-0{
  box-shadow: none;
}

/**====== custom scrollbar css start ======**/
.digits{
  font-family:$font-work-sans, $font-serif;
}
.custom-scrollbar {
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px $light-gray;
  }
  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba($primary-color, 0.10);
  }
}

/**====== Custom scrollbar css end ======**/

/**====== Animation css Start ======**/
.line{
  pre{
    font-size: 100%;
  }
}
/**====== Animation css end ======**/

/**====== list style css Start ======**/
.list-circle{
  list-style: circle;
}
/**====== list style css end ======**/
/**=====================
      Generic CSS Ends
==========================**/


/**=====================
     Chat CSS Start
==========================**/
.chat-box {
  .toogle-bar{
    display: none;
  }
  .people-list {
    .search{
      position: relative;
      .form-control{
        background-color: $light-body-bg-color;
        border: 1px solid $light-color;
        &::placeholder{
          color: $semi-dark;
        }
      }
      i {
        position: absolute;
        right: 10px;
        top: 10px;
        font-size: 14px;
        color: $light-gray;
      }
    }
    ul {
      padding: 0;
      li {
        padding-bottom: 20px;
        &:last-child{
          padding-bottom: 0;
        }
      }
    }
  }
  .user-image {
    float: left;
    width: 52px;
    height: 52px;
    margin-right: 5px;
  }
  .about {
    float: left;
    margin-top: 5px;
    padding-left: 10px;
    .name {
      color: $dark-color;
      letter-spacing: 1px;
      font-weight: 600;
    }
  }
  .status {
    color: $semi-dark;
    letter-spacing: 1px;
    font-size: 12px;
    margin-top: 5px;
    .chat-status{
      font-weight:600;
      color: $theme-body-font-color;
    }
    p{
      font-size:14px;
    }
  }
  .chat-right-aside{
    .chat {
      .chat-header {
        padding: 15px;
        border-bottom: 1px solid $light-color;
        img {
          float: left;
          width: 50px;
          height: 50px;
          box-shadow: 1px 1px 4px 1px $light-gray;
        }
        .chat-menu-icons{
          margin-top: 15px;
          li{
            margin-right:24px;
            a i{
              color: $theme-body-sub-title-color;
              font-size: 25px;
              cursor: pointer;
            }
          }
        }
      }
      .chat-msg-box {
        padding: 20px;
        overflow-y: auto;
        height: 560px;
        margin-bottom: 90px;
        .chat-user-img{
          margin-top: -35px;
        }
        .message-data {
          margin-bottom: 10px;
        }
        .message-data-time {
          letter-spacing: 1px;
          font-size: 12px;
          color: $semi-dark;
          font-family: $font-work-sans, $font-serif;
        }
        .message {
          color: $dark-color;
          padding: 20px;
          line-height: 1.9;
          letter-spacing: 1px;
          font-size: 14px;
          margin-bottom: 30px;
          width: 50%;
          position: relative;
        }
        .my-message {
          border: 1px solid $light-color;
          border-radius: 10px;
          border-top-left-radius: 0;
        }
        .other-message {
          background-color: $light ;
          border-radius: 10px;
          border-top-right-radius: 0;
        }
      }
      .chat-message {
        padding: 20px;
        border-top: 1px solid $light-body-bg-color;
        position: absolute;
        width: calc(100% - 15px);
        background-color: $white;
        bottom: 0;
        .smiley-box{
          background: $light-semi-gray;
          padding: 10px;
          display: block;
          border-radius: 4px;
          margin-right: 0.5rem;
        }
        .text-box{
          position: relative;
          .input-txt-bx {
            height: 50px;
            border: 2px solid $primary-color;
            padding-left: 18px;
            font-size: 12px;
            letter-spacing: 1px;
          }
          i{
            position: absolute;
            right: 20px;
            top: 20px;
            font-size: 20px;
            color: $light-gray;
            cursor: pointer;
          }
          .btn{
            font-size: 16px;
            font-weight: 500;
          }
        }
      }
    }
  }
  .chat-menu {
    border-left: 1px solid $light-color;
    .tab-pane {
      padding: 0 15px;
    }
    ul{
      li{
        .about{
          .status{
            i{
              font-size: 10px;
            }
          }
        }
      }
    }
    .user-profile {
      margin-top: 30px;
      .user-content{
        h5{
          margin: 25px 0;
        }
        hr{
          margin: 25px 0;
        }
        p{
          font-size: 16px;
        }
      }
      .image {
        position: relative;
        .icon-wrapper {
          position: absolute;
          bottom: 0;
          left: 55%;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 35px;
          width: 35px;
          border-radius: 50%;
          background-color: $white;
          cursor: pointer;
          overflow: hidden;
          margin: 0 auto;
          font-size: 14px;
          box-shadow: 1px 1px 3px 1px $light-color;
        }
        .avatar img {
          border-radius: 50%;
          border: 5px solid $light-color;
        }
      }
      .border-right {
        border-right: 1px solid $light-color;
      }
      .follow {
        margin-top: 0;
        .follow-num {
          font-size: 22px;
          color: $black;
        }
        span {
          color: $theme-font-color;
          font-size: 14px;
          letter-spacing: 1px;
        }
      }
      .social-media a {
        color: $semi-dark;
        font-size: 15px;
        padding: 0 7px;
      }
      .chat-profile-contact{
        p{
          font-size:14px;
          color:$semi-dark;
        }
      }
    }
    .nav{
      margin-bottom: 20px;
    }
    .nav-tabs .nav-item {
      width:33.33%;
      a {
        padding: 15px!important;
        color: $semi-dark!important;
        letter-spacing: 1px;
        font-size: 14px;
        font-weight: 600;
        height: 80px;
        display:flex;
        align-items:center;
        justify-content:center;
      }
      .material-border{
        border-width:1px;
        border-color:$primary-color;
      }
      .nav-link.active {
        color: $black!important;
      }
    }
  }
  .chat-history{
    .call-content {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 300px;
    }
    .total-time h2 {
      font-size: 50px;
      color: $light-semi-gray;
      font-weight: 600;
      margin-bottom: 30px;
    }
    .receiver-img{
      margin-top: 55px;
      img{
        border-radius: 5px;
      }
    }
    .call-icons{
      margin-bottom: 35px;
      ul{
        li {
          width: 60px;
          height: 60px;
          border: 1px solid $light-color;
          border-radius: 50%;
          padding: 12px;
          + li{
            margin-left: 10px;
          }
          a {
            color: #999;
            font-size: 25px;
          }
        }
      }
    }
  }
}
.status-circle{
  width: 10px;
  height: 10px;
  position: absolute;
  top:40px;
  left:40px;
  border-radius: 50%;
  border:2px solid $white;
}
.away{
  background-color: $warning-color;
}
.online{
  background-color: $success-color;
}
.offline{
  background-color: $danger-color;
}
.chat-container{
  .aside-chat-left{
    width:320px;
  }
  .chat-right-aside{
    width:320px;
  }
}
.call-chat-sidebar{
  max-width: 320px;
}
.call-chat-sidebar,.chat-body{
  .card{
    .card-body{
      padding: 15px;
    }
  }
}
/**=====================
      Chat CSS Ends
==========================**/

/**=====================
      market-chart CSS Start
==========================**/
.market-chart {
  height: 303px;
}
/*=====================
    radial-bar CSS start
==========================*/

.radial-bar {
  position: relative;
  display: inline-block;
  border-radius: 50%;
  background-color: transparent;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  width: 70px;
  height: 70px;
  font-size: 18px;
  font-family: work-Sans, sans-serif;
  background-clip: content-box;
  &:after {
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 50%;
    text-align: center;
    font-weight: 500;
    color: #455a64;
    content: attr(data-label);
    background-color: #fff;
    z-index: 1;
    width: 60px;
    height: 60px;
    margin-left: 5px;
    margin-top: 5px;
    line-height: 56px;
  }
  > img {
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 50%;
    text-align: center;
    font-weight: 500;
    color: #455a64;
    z-index: 3;
    width: 60px;
    height: 60px;
    margin-left: 5px;
    margin-top: 5px;
    line-height: 56px;
  }
  &.radial-bar-0 {
    background-image: linear-gradient(90deg, #eff0f1 50%, transparent 50%, transparent), linear-gradient(90deg, #448aff 50%, #eff0f1 50%, #eff0f1);
  }
  &.radial-bar-5 {
    background-image: linear-gradient(90deg, #eff0f1 50%, transparent 50%, transparent), linear-gradient(108deg, #448aff 50%, #eff0f1 50%, #eff0f1);
  }
  &.radial-bar-10 {
    background-image: linear-gradient(90deg, #eff0f1 50%, transparent 50%, transparent), linear-gradient(126deg, #448aff 50%, #eff0f1 50%, #eff0f1);
  }
  &.radial-bar-15 {
    background-image: linear-gradient(90deg, #eff0f1 50%, transparent 50%, transparent), linear-gradient(144deg, #448aff 50%, #eff0f1 50%, #eff0f1);
  }
  &.radial-bar-20 {
    background-image: linear-gradient(90deg, #eff0f1 50%, transparent 50%, transparent), linear-gradient(162deg, #448aff 50%, #eff0f1 50%, #eff0f1);
  }
  &.radial-bar-25 {
    background-image: -webkit-gradient(linear, left top, right top, color-stop(50%, #eff0f1), color-stop(50%, transparent), to(transparent)), -webkit-gradient(linear, left top, left bottom, color-stop(50%, #448aff), color-stop(50%, #eff0f1), to(#eff0f1));
    background-image: linear-gradient(90deg, #eff0f1 50%, transparent 50%, transparent), linear-gradient(180deg, #448aff 50%, #eff0f1 50%, #eff0f1);
  }
  &.radial-bar-30 {
    background-image: linear-gradient(90deg, #eff0f1 50%, transparent 50%, transparent), linear-gradient(198deg, #448aff 50%, #eff0f1 50%, #eff0f1);
  }
  &.radial-bar-35 {
    background-image: linear-gradient(90deg, #eff0f1 50%, transparent 50%, transparent), linear-gradient(216deg, #448aff 50%, #eff0f1 50%, #eff0f1);
  }
  &.radial-bar-40 {
    background-image: linear-gradient(90deg, #eff0f1 50%, transparent 50%, transparent), linear-gradient(234deg, #448aff 50%, #eff0f1 50%, #eff0f1);
  }
  &.radial-bar-45 {
    background-image: linear-gradient(90deg, #eff0f1 50%, transparent 50%, transparent), linear-gradient(252deg, #448aff 50%, #eff0f1 50%, #eff0f1);
  }
  &.radial-bar-50 {
    background-image: linear-gradient(270deg, #448aff 50%, transparent 50%, transparent), linear-gradient(270deg, #448aff 50%, #eff0f1 50%, #eff0f1);
  }
  &.radial-bar-55 {
    background-image: linear-gradient(288deg, #448aff 50%, transparent 50%, transparent), linear-gradient(270deg, #448aff 50%, #eff0f1 50%, #eff0f1);
  }
  &.radial-bar-60 {
    background-image: linear-gradient(306deg, #448aff 50%, transparent 50%, transparent), linear-gradient(270deg, #448aff 50%, #eff0f1 50%, #eff0f1);
  }
  &.radial-bar-65 {
    background-image: linear-gradient(324deg, #448aff 50%, transparent 50%, transparent), linear-gradient(270deg, #448aff 50%, #eff0f1 50%, #eff0f1);
  }
  &.radial-bar-70 {
    background-image: linear-gradient(342deg, #448aff 50%, transparent 50%, transparent), linear-gradient(270deg, #448aff 50%, #eff0f1 50%, #eff0f1);
  }
  &.radial-bar-75 {
    background-image: linear-gradient(360deg, #448aff 50%, transparent 50%, transparent), linear-gradient(270deg, #448aff 50%, #eff0f1 50%, #eff0f1);
  }
  &.radial-bar-80 {
    background-image: linear-gradient(378deg, #448aff 50%, transparent 50%, transparent), linear-gradient(270deg, #448aff 50%, #eff0f1 50%, #eff0f1);
  }
  &.radial-bar-85 {
    background-image: linear-gradient(396deg, #448aff 50%, transparent 50%, transparent), linear-gradient(270deg, #448aff 50%, #eff0f1 50%, #eff0f1);
  }
  &.radial-bar-90 {
    background-image: linear-gradient(414deg, #448aff 50%, transparent 50%, transparent), linear-gradient(270deg, #448aff 50%, #eff0f1 50%, #eff0f1);
  }
  &.radial-bar-95 {
    background-image: linear-gradient(432deg, #448aff 50%, transparent 50%, transparent), linear-gradient(270deg, #448aff 50%, #eff0f1 50%, #eff0f1);
  }
  &.radial-bar-100 {
    background-image: linear-gradient(450deg, #448aff 50%, transparent 50%, transparent), linear-gradient(270deg, #448aff 50%, #eff0f1 50%, #eff0f1);
  }
}

.radial-bar-primary {
  &.radial-bar-0 {
    background-image: linear-gradient(90deg, $primary_color  50%, transparent 50%, transparent), linear-gradient(90deg, $primary_color  50%, #eff0f1 50%, #eff0f1);
  }
  &.radial-bar-5 {
    background-image: linear-gradient(90deg, $primary_color  50%, transparent 50%, transparent), linear-gradient(108deg, $primary_color  50%, #eff0f1 50%, #eff0f1);
  }
  &.radial-bar-10 {
    background-image: linear-gradient(90deg, $primary_color  50%, transparent 50%, transparent), linear-gradient(126deg, $primary_color  50%, #eff0f1 50%, #eff0f1);
  }
  &.radial-bar-15 {
    background-image: linear-gradient(90deg, $primary_color  50%, transparent 50%, transparent), linear-gradient(144deg, $primary_color  50%, #eff0f1 50%, #eff0f1);
  }
  &.radial-bar-20 {
    background-image: linear-gradient(90deg, $primary_color  50%, transparent 50%, transparent), linear-gradient(162deg, $primary_color  50%, #eff0f1 50%, #eff0f1);
  }
  &.radial-bar-25 {
    background-image: linear-gradient(90deg, $primary_color  50%, transparent 50%, transparent), linear-gradient(180deg, $primary_color  50%, #eff0f1 50%, #eff0f1);
  }
  &.radial-bar-30 {
    background-image: linear-gradient(90deg, $primary_color  50%, transparent 50%, transparent), linear-gradient(198deg, $primary_color  50%, #eff0f1 50%, #eff0f1);
  }
  &.radial-bar-35 {
    background-image: linear-gradient(90deg, $primary_color  50%, transparent 50%, transparent), linear-gradient(216deg, $primary_color  50%, #eff0f1 50%, #eff0f1);
  }
  &.radial-bar-40 {
    background-image: linear-gradient(90deg, $primary_color  50%, transparent 50%, transparent), linear-gradient(234deg, $primary_color  50%, #eff0f1 50%, #eff0f1);
  }
  &.radial-bar-45 {
    background-image: linear-gradient(90deg, $primary_color  50%, transparent 50%, transparent), linear-gradient(252deg, $primary_color  50%, #eff0f1 50%, #eff0f1);
  }
  &.radial-bar-50 {
    background-image: linear-gradient(270deg, $primary_color  50%, transparent 50%, transparent), linear-gradient(270deg, $primary_color  50%, #eff0f1 50%, #eff0f1);
  }
  &.radial-bar-55 {
    background-image: linear-gradient(288deg, $primary_color  50%, transparent 50%, transparent), linear-gradient(270deg, $primary_color  50%, #eff0f1 50%, #eff0f1);
  }
  &.radial-bar-60 {
    background-image: linear-gradient(306deg, $primary_color  50%, transparent 50%, transparent), linear-gradient(270deg, $primary_color  50%, #eff0f1 50%, #eff0f1);
  }
  &.radial-bar-65 {
    background-image: linear-gradient(324deg, $primary_color  50%, transparent 50%, transparent), linear-gradient(270deg, $primary_color  50%, #eff0f1 50%, #eff0f1);
  }
  &.radial-bar-70 {
    background-image: linear-gradient(342deg, $primary_color  50%, transparent 50%, transparent), linear-gradient(270deg, $primary_color  50%, #eff0f1 50%, #eff0f1);
  }
  &.radial-bar-75 {
    background-image: linear-gradient(360deg, $primary_color  50%, transparent 50%, transparent), linear-gradient(270deg, $primary_color  50%, #eff0f1 50%, #eff0f1);
  }
  &.radial-bar-80 {
    background-image: linear-gradient(378deg, $primary_color  50%, transparent 50%, transparent), linear-gradient(270deg, $primary_color  50%, #eff0f1 50%, #eff0f1);
  }
  &.radial-bar-85 {
    background-image: linear-gradient(396deg, $primary_color  50%, transparent 50%, transparent), linear-gradient(270deg, $primary_color  50%, #eff0f1 50%, #eff0f1);
  }
  &.radial-bar-90 {
    background-image: linear-gradient(414deg, $primary_color  50%, transparent 50%, transparent), linear-gradient(270deg, $primary_color  50%, #eff0f1 50%, #eff0f1);
  }
  &.radial-bar-95 {
    background-image: linear-gradient(432deg, $primary_color  50%, transparent 50%, transparent), linear-gradient(270deg, $primary_color  50%, #eff0f1 50%, #eff0f1);
  }
  &.radial-bar-100 {
    background-image: linear-gradient(450deg, $primary_color  50%, transparent 50%, transparent), linear-gradient(270deg, $primary_color  50%, #eff0f1 50%, #eff0f1);
  }
}

.market-chart{
  .ct-series-a {
    .ct-point{
      stroke: $primary_color;
    }
    .ct-line{
      stroke: $primary_color;
    }
    .ct-bar{
      stroke: $primary_color;
    }
    .ct-slice-donut{
      stroke: $primary_color;
    }
  }
  .ct-series-b{
    .ct-point{
      stroke: $danger-color;
    }
    .ct-line{
      stroke: $danger-color;
    }
    .ct-bar{
      stroke: $danger-color;
    }
    .ct-slice-donut{
      stroke: $danger-color;
    }
  }
}
.dropzone-custom {
  .dropzone {
    text-align: center;
    .fa-cloud-upload {
      font-size: 50px;
      color: $primary-color;
    }
    h4 {
      font-size: 18px;
    }
  }
  .dropzone-border {
    color: #717386;
    border: 2px dashed $theme-deafult;
    .fa-cloud-upload {
      font-size: 50px;
      color: $theme-deafult;
    }
  }
}
.needs-validation{
  .custom-select {
    background: $white;
    font-size: 14px;
  }
  .form-control{
    border-radius: 0.25rem;
    padding: 0.48rem 0.75rem;
  }
}

.license-key[disabled]{
  cursor: not-allowed;
}
.jsgrid-cell,.jsgrid-grid-body{
  border: 1px solid #ebf1ff;
}
.jsgrid {
  .jsgrid-pager-container {
    text-align: right;
  }
  .jsgrid-pager [class*="jsgrid-pager"] {
    display: inline-block;
    min-width: 1.5em;
    padding: 0.5em 1em;
    border: 1px solid #f8f8f9;
  }
  .jsgrid-pager .jsgrid-pager-nav-inactive-button {
    margin-right: -5px;
  }
}
.jsgrid-alt-row {
  .jsgrid-cell{
    background-color: rgba(241, 244, 251, 0.5);
    text-align: center;
  }
}
.jsgrid-row{
  > .jsgrid-cell {
    text-align: center;
  }
}
.jsgrid-filter-row {
  .jsgrid-cell{
    background-color: #f8f8f9;
    text-align: center;
  }
}
.jsgrid-header-row{
  .jsgrid-header-cell{
    background-color: #f1f4fb;
    text-align: center;
    padding: 0.75rem;
  }
}
.jsgrid-selected-row{
  > .jsgrid-cell{
    background-color: rgba(241, 244, 251, 0.5);
    border-color: #f1f4fb;
  }
}

.checkbox{
  label {
    display: inline-block;
    position: relative;
    cursor: pointer;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .form-check-input{
    margin-top: 13px;
    margin-bottom: 10px;
  }
}
.form-row{
  .form-control {
    font-size: 14px;
    letter-spacing: 1px;
  }
  .custom-select{
    font-size: 14px;
    letter-spacing: 1px;
  }
}

/*=====================
   bulk-pruduct CSS start
==========================*/
.bulk-pruduct{
  .card{
    .card-body{
      p{font-weight: bold;}
    }
  }
}
/**=====================
   Datepicker CSS Start
==========================**/
.datepicker {
  box-shadow: 0 4px 14px rgba($primary-color, 0.15);
}
.datepicker--day-name {
  color: $black;
  font-weight: bold;
}
.datepicker--cell {
  &.-current- {
    color: $black;
    //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
    border-radius: 5px;
    font-weight: bold;
    border: 2px solid $primary-color;
  }
  &.-focus- {
    background: $primary-color;
    color: $white;
  }
  &.-selected- {
    background: $primary-color;
  }
}
.daterangepicker.ltr {
  color: $dark-gray;
  z-index: 8;
  tr {
    font-size: 13px;
    td {
      height: 34px;
      width: 34px;
      &.in-range {
        background-color: $auth-bg-color;
      }
      &.active {
        background-color: $primary-color;
      }
    }
  }
  .ranges {
    float: none;
    li {
      color: $primary-color;
      &:hover {
        background-color: $primary-color;
        border: 1px solid $primary-color;
        color: $white;
      }
      &.active {
        background-color: $primary-color;
        border: 1px solid $primary-color;
        color: $white;
      }
    }
  }
  .calendar-time select {
    color: $dark-gray;
  }
}
.datetime-picker {
  .bootstrap-datetimepicker-widget.dropdown-menu {
    width: auto;
  }
}
.date-range-picker{
  .theme-form{
    margin-bottom: 30px;
  }
  > div{
    &:last-child{
      .theme-form{
        margin-bottom: 0px;
      }
    }
  }
}
/**=====================
    Datepicker CSS End
==========================**/
/*=====================
    Responsive CSS start
==========================*/
@media only screen and (max-width: 1660px) {
  .peity-chart-dashboard {
    .peity {
      height: 148px;
    }
  }
  .products-table {
    table {
      tr {
        td {
          &:first-child {
            min-width: 268px;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1440px) and (min-width: 1366px) {
  .peity-chart-dashboard {
    .peity {
      height: 120px;
    }
  }
  .flot-chart-container {
    height: 350px;
  }
  .product-adding {
    .col-xl-5 {
      flex: 0 0 36%;
      max-width: 36%;
    }
    .col-xl-7 {
      flex: 0 0 64%;
      max-width: 64%;
    }
  }
}
@media only screen and (max-width: 1366px) {
  .order-responsive {
    datatable-body-cell, datatable-header-cell {
      width: 160px !important;
    }
    .datatable-row-center {
      overflow: auto;
    }
    datatable-selection {
      white-space: nowrap;
    }
  }
  .order-graph {
    .ct-4 {
      &.flot-chart-container {
        height: auto;
      }
      x-chartist {
        svg {
          height: 360px !important;
        }
      }
    }
  }
  .user-image {
    table {
      tr {
        th,td {
          &:nth-child(6) {
            width: 300px !important;
          }
        }
      }
    }
  }
  .page-main-header {
    .main-header-right {
      .nav-right {
        > ul {
          > li {
            &:first-child {
              width: 32%;
            }
          }
        }
      }
    }
  }
  .peity-chart-dashboard {
    .peity {
      height: 180px;
    }
  }
  .xl-space {
    margin-top: 30px;
  }
  .chart-vertical-center{
    #myDoughnutGraph, #myPolarGraph{
      height: 180px !important;
    }
  }
  .product-adding {
    .add-product-form {
      margin-top: 30px;
    }
  }
  .user-list {
    table {
      tr {
        td, th {
          &:nth-child(5) {
            width: 195px !important;
          }
        }
      }
    }
  }
  .translation-list {
    table {
      tr {
        td, th {
          width: 162px !important;
          &:first-child, &:nth-child(2) {
            width: 274px !important;
          }
        }
      }
    }
  }
  .order-datatable {
    table {
      display: block;
      tr {
        th,td {
          min-width: 121px;
          &:nth-child(2) {
            min-width: 150px;
          }
        }
      }
    }
  }
  .vendor-table {
    table {
      display: block;
      tr {
        th,td {
          min-width: 112px;
          &:first-child {
            min-width: 180px;
          }
        }
      }
    }
  }
  // media
  .media-table {
    table {
      tr {
        th,td {
          &:nth-child(3) {
            width: 146px !important;
          }
          &:last-child {
            width: 248px !important;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 1199px) {
  // product detail
  .product-right {
    h2 {
      font-size: 20px;
    }
  }
  .product-page-details {
    margin-top: 30px;
  }
  // datatable
  .menu-list-table {
    table {
      tr {
        th,td {
          width: 300px !important;
        }
      }
    }
  }
  .product-physical {
    table {
      tr {
        th,td {
          width: 70px !important;
          &:nth-child(2) {
            width: 173px !important;
          }
          &:nth-child(5) {
            width: 100px !important;
          }
        }
      }
    }
  }
  .category-table {
    table {
      tr {
        td, th {
          width: 120px !important;
          &:nth-child(2) {
            width: 500px !important;
          }
        }
      }
    }
  }
  .media-table {
    table {
      tr {
        td, th {
          &:last-child {
            width: 370px !important;
          }
        }
      }
    }
  }
  .order-table {
    table {
      tr {
        td, th {
          &:nth-child(4){
            width: 230px !important;
          }
        }
      }
    }
  }
  .user-list {
    table {
      tr {
        th,td {
          width: 200px !important;
        }
      }
    }
  }
  .transactions {
    table {
      tr {
        td, th {
          &:nth-child(4) {
            width: 100px !important;
          }
          &:first-child {
            width: 100px !important;
          }
          &:nth-child(2) {
            width: 150px !important;
          }
        }
      }
    }
  }
}
@media screen and (max-device-width: 1366px) and (min-device-width: 1200px){
  .xl-100 {
    max-width: 100%;
    flex: 0 0 100%;
  }
  .xl-50{
    max-width: 50%;
    flex: 0 0 50%;
  }
  .product-adding {
    .col-xl-5, .col-xl-7 {
      flex: 0 0 100%;
      max-width: 100%;
    }
    .add-product-form {
      margin-top: 30px;
    }
  }
  // datatable
  .category-table {
    table {
      tr {
        td, th {
          &:first-child {
            width: 90px !important;
          }
        }
      }
    }
  }
  .order-table {
    table {
      tr {
        td, th {
          &:nth-child(2){
            width: 70px !important;
          }
        }
      }
    }
  }
  .user-list {
    table {
      tr {
        td, th {
          &:first-child {
            width: 130px !important;
          }
        }
      }
    }
  }
  // profile
  .profile-table {
    table {
      tr {
        th,td {
          width: 200px !important;
        }
      }
    }
  }
}
@media only screen and (max-width: 991px){
  .report-employee {
    .flot-chart-container {
      height: 310px;
    }
  }
  .authentication-box {
    padding: 30px;
    .container {
      .bg-primary {
        padding: 40px;
      }
    }
  }
  ng2-smart-table {
    ng2-smart-table-pager {
      margin-top: 20px;
    }
  }
  .btn-popup {
    margin-bottom: 20px;
  }
  .order-graph {
    .order-graph-bottom {
      margin-top: 20px;
    }
  }
  .xl-space {
    margin-top: 20px;
  }
  .card {
    .card-body, .card-header {
      padding: 20px;
      .card-header-right {
        right: 11px;
        top: 15px;
      }
    }
  }
  .page-wrapper{
    .page-main-header.open{
      z-index: 11;
    }
    .page-main-header {
      .main-header-left {
        .logo-wrapper {
          img {
            margin-left: -10px;
          }
        }
      }
    }
  }
  .right-sidebar {
    top: 133px;
  }
  .offcanvas {
    .page-wrapper {
      .page-body-wrapper {
        .page-body{
          &:before{
            left: 0;
          }
        }
      }
    }
  }
  .page-wrapper {
    .page-body-wrapper {
      .page-header{
        .row{
          h3 {
            font-size: 22px;
          }
          .pull-right {
            float: none;
            margin-top: 5px;
          }
        }
      }
      .page-sidebar {
        top: 60px !important;
        height: calc(100vh - 60px) !important;
        ~ {
          .page-body {
            margin-left: 0;
            margin-top: 60px;
            .page-header{
              .row{
                .page-header-left{
                  display: inherit;
                }
              }
            }
          }
        }
      }
      .sidebar {
        height: calc(100vh - 60px);
      }
      .page-sidebar.open {
        ~ {
          .page-body {
            margin-top: 60px;
            .activity {
              .media {
                .gradient-round.gradient-line-1 {
                  &:after {
                    bottom: -45px;
                  }
                }
                .gradient-round.small-line {
                  &:after {
                    bottom: -28px;
                  }
                }
                .gradient-round.medium-line {
                  &:after {
                    bottom: -41px;
                  }
                }
              }
            }
          }
        }
      }
      footer {
        margin-left: 0;
      }
    }
    .page-main-header {
      height: 60px;
      margin-left: 0;
      width: 100%;
      z-index: 11;
      .main-header-left {
        .logo-wrapper {
          img {
            height: 23px;
            margin-top: 0;
            margin-right: 15px;
          }
        }
      }
      .main-header-right {
        .nav-right {
          position: unset;
          padding: 0;
          >ul {
            top: 60px;
            position: absolute;
            z-index: -1;
            background-color: #fff;
            -webkit-transition: all linear 0.3s;
            transition: all linear 0.3s;
            -webkit-box-shadow: 0 2px 2px 2px #efefef;
            box-shadow: 0 2px 2px 2px #efefef;
            width: 100%;
            left: 0;
            padding: 0 40px;
            -webkit-transform: translateY(-35px) scaleY(0);
            transform: translateY(-35px) scaleY(0);
            opacity: 0;
            visibility: hidden;
            >li {
              margin: 10px 0;
            }
          }
          >.mobile-toggle {
            cursor: pointer;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            padding-top: 9px;
            svg {
              circle {
                color: $primary_color;
              }
            }
          }
          >ul.open {
            z-index: 1;
            opacity: 1;
            -webkit-transform: translateY(0px) scaleY(1);
            transform: translateY(0px) scaleY(1);
            visibility: visible;
          }
        }
      }
    }
  }
  .page-header {
    padding-top: 25px !important;
    padding-bottom: 25px !important;
    .row {
      .page-header-left {
        .breadcrumb {
          margin-top: 5px;
          margin-left: 0;
        }
      }
    }
  }
  // product detail
  #carousel-container {
    > .current-figure {
      > #current-image, > #current-image-legacy {
        margin-bottom: 20px;
      }
    }
  }
  .product-right {
    h2 {
      margin-top: 15px;
      font-size: 20px;
    }
  }
  .product-page-details {
    text-align: left !important;
    margin-top: 0;
  }
  .product-page-main {
    .owl-stage-outer {
      margin-top: 20px;
    }
  }
  // order
  .dataTables_wrapper {
    .dataTables_length {
      margin-bottom: 20px;
    }
  }
  // add product page
  .add-product {
    img {
      width: 460px;
    }
  }
  .zoomContainer {
    top: 209px !important;
  }
  .product-adding {
    .add-product-form {
      margin-top: 20px;
    }
  }
  // tab css
  .tab-coupon {
    margin-bottom: 20px;
  }
  // profile
  .deactivate-account {
    margin-top: 20px;
  }
  // reports
  .sell-graph {
    canvas {
      height: 312px !important;
    }
  }
  // transcation
  .transcation-datatable {
    table {
      th,td {
        &:nth-child(3) {
          width: 230px !important;
        }
      }
    }
  }
}
@media only screen and (max-width: 767px){
  .report-employee {
    .flot-chart-container {
      &.report-full {
        svg {
          width: 750px !important;
        }
      }
    }
  }
  .card-right {
    margin-left: 0;
    .card {
      padding-left: 0;
    }
  }
  .authentication-box {
    .form-footer, .form-button {
      text-align: center;
      >span {
        padding: 0;
        left: 0;
        right: 0;
      }
    }
  }
  .datepicker-here {
    width: auto !important;
  }
  .input-grp-p {
    padding: 0 15px;
  }
  .created-width {
    table {
      tr {
        th,td {
          &:last-child {
            width: 200px !important;
          }
        }
      }
    }
  }
  .custom-datatable {
    .ngx-datatable.bootstrap {
      .datatable-footer {
        .datatable-pager {
          a {
            min-width: 32px !important;
            line-height: 34px !important;
          }
        }
      }
    }
  }
  .page-main-header {
    .main-header-right {
      .nav-right {
        > ul {
          > li {
            &:first-child {
              width: 40%;
            }
          }
        }
      }
    }
  }
  .products-table {
    table {
      tr {
        td, th {
          &:first-child {
            min-width: 280px;
          }
          &:nth-child(3) {
            min-width: 110px;
          }
        }
      }
    }
  }
  .page-wrapper {
    .page-body-wrapper {
      .page-header {
        .row {
          .col {
            flex-basis: unset;
            .pull-right {
              float: none;
              margin-top: 20px;
            }
          }
        }
      }
      footer {
        .row {
          div {
            text-align: center;
            .pull-right {
              float: none;
            }
          }
        }
      }
    }
  }
  .footer-fix{
    .pull-right{
      float: none !important;
      text-align: center !important;
    }
  }
  .product-physical {
    table {
      tr {
        th,td {
          width: 90px !important;
          &:nth-child(2) {
            width: 196px !important;
          }
        }
      }
    }
  }
  // add product page
  .add-product-form {
    .form-group {
      .form-control, select {
        width: 100% !important;
        margin: 0 !important;
      }
    }
    .input-group {
      .touchspin {
        width: 30% !important;
      }
    }
    .radio-animated {
      label {
        margin-bottom: 0;
      }
    }
    .editor-space {
      padding: 0 15px;
    }
    textarea {
      margin: 0 15px;
    }
  }
  .add-product {
    img {
      width: 400px;
    }
  }
  .digital-add {
    textarea {
      margin: 0 !important;
    }
    .form-group {
      select, .form-control {
        width: 100% !important;
      }
      label {
        margin-bottom: 0 !important;
      }
    }
  }
  // digital list
  .digital-product {
    table {
      tr {
        th,td {
          width: 120px !important;
        }
      }
    }
  }
  // report page
  .report-table {
    table {
      tr {
        th,td {
          width: 150px !important;
        }
      }
    }
  }
  // coupon page
  .needs-validation {
    .form-group {
      .form-control, select {
        width: 95%;
        margin: 0 auto;
      }
      .checkbox {
        padding-left: 15px;
      }
      label {
        margin-bottom: 4px;
      }
    }
    .radio-animated {
      label {
        margin-bottom: 0;
      }
    }
    .editor-space {
      padding: 0 15px;
    }
    textarea {
      margin: 0 15px;
    }
  }
  // order
  .dataTables_wrapper {
    .dataTables_paginate {
      float: none !important;
      margin-top: 20px !important;
    }
    .dataTables_length, .dataTables_filter {
      label {
        float: none !important;
      }
    }
    table {
      &.dataTable {
        margin-top: 20px !important;
      }
    }
  }
}
@media only screen and (max-width: 577px){
  footer {
    margin-bottom: 0!important;
  }
}
@media only screen and (max-width: 575px){
  .report-employee {
    .flot-chart-container {
      &.report-full {
        svg {
          width: 480px !important;
        }
      }
    }
  }
  .profile-tabs {
    display: block !important;
  }
  .vendor-list {
    img {
      margin-right: 15px;
    }
  }
  .created-width {
    table {
      tr {
        th,td {
          &:last-child {
            width: 250px !important;
          }
        }
      }
    }
  }
  .custom-datatable {
    input {
      width: 56% !important;
    }
  }
  .ng2-smart-filter {
    input, select {
      width: 100% !important;
    }
  }
  .order-graph {
    .ct-4 {
      x-chartist {
        svg {
          height: 250px !important;
        }
      }
    }
  }
  .justify-content-start {
    justify-content: inherit !important;
  }
  .custom-datatable {
    .ngx-datatable.bootstrap {
      .datatable-footer {
        .datatable-pager {
          a {
            min-width: 22px !important;
            line-height: 27px !important;
          }
        }
      }
    }
  }
  ng2-smart-table {
    ng2-smart-table-pager {
      margin-top: 15px;
    }
  }
  .btn-popup {
    margin-bottom: 15px;
  }
  .static-top-widget {
    div {
      &.align-self-center {
        svg {
          width: 20px;
          height: 20px;
        }
      }
    }
  }
  .latest-order-table {
    table {
      tr {
        td {
          min-width: 100px;
          + td {
            min-width: 150px;
          }
          &:nth-child(3) {
            min-width: 200px;
          }
        }
      }
    }
  }
  .card{
    margin-bottom: 15px;
    .card-header{
      padding: 15px;
      h5 {
        font-size: 17px;
      }
      .card-header-right {
        right: 6px;
        top: 10px;
      }
    }
    .card-body {
      padding: 15px;
    }
  }
  .sm-order-space, .xl-space {
    margin-top: 15px;
  }
  .order-graph {
    .order-graph-bottom {
      margin-top: 15px;
      h6 {
        margin-right: 0;
      }
      .media {
        margin-bottom: 15px;
      }
    }
  }
  .offcanvas {
    .page-wrapper {
      .page-body-wrapper {
        .page-header{
          .row{
            h3 {
              font-size: 22px;
            }
          }
        }
        .page-body{
          &:before{
            left: 0;
          }
        }
      }
    }
  }
  .form-inline {
    .form-control-plaintext {
      display: inline-block;
    }
  }
  .d-sm-none {
    &:after{
      display: none;
    }
  }
  ul {
    &.notification-dropdown {
      &.onhover-show-div {
        width: 284px;
        right: -112px;
        &:before, &:after {
          right: 135px !important;
        }
      }
    }
  }
  .page-wrapper {
    .page-main-header {
      .main-header-right {
        padding: 0 15px;
        .nav-right {
          >ul {
            padding: 0 0;
            justify-content: flex-start;
            >li {
              &:first-child {
                .search-form {
                  .form-group {
                    &:before {
                      display: none;
                    }
                    &:after {
                      display: none;
                    }
                  }
                }
                width: auto;
                margin: 0;
              }
            }
            .search-form {
              .form-group {
                margin-bottom: 0;
              }
              .form-control-plaintext.open {
                -webkit-transform: translateY(0px) scaleY(1);
                transform: translateY(0px) scaleY(1);
                opacity: 1;
                visibility: visible;
                -webkit-transition: all linear 0.3s;
                transition: all linear 0.3s;
              }
              .form-control-plaintext {
                top: 60px;
                position: absolute;
                -webkit-transition: all linear 0.3s;
                transition: all linear 0.3s;
                left: 0;
                background-color: #fff;
                -webkit-transform: translateY(-35px) scaleY(0);
                transform: translateY(-35px) scaleY(0);
                opacity: 0;
                visibility: hidden;
                width: 180px;
                padding: 10px 10px 10px 15px;
              }
              .mobile-search {
                svg {
                  color: $primary_color;
                }
              }
            }
          }
        }
      }
    }
    .search-form {
      .form-group {
        margin-right: 0;
      }
    }
    .page-body-wrapper {
      .page-sidebar.open {
        ~ {
          .page-body {
            .activity {
              .media {
                .gradient-round.gradient-line-1 {
                  &:after {
                    bottom: -41px;
                  }
                }
                .gradient-round.small-line {
                  &:after {
                    bottom: -19px;
                    height: 12px;
                  }
                }
                .gradient-round.medium-line {
                  &:after {
                    bottom: -34px;
                  }
                }
              }
            }
          }
        }
      }
      .page-body {
        padding: 0;
      }
      .page-header {
        padding-top: 20px !important;
        padding-bottom: 20px !important;
      }
    }
  }
  // product detail
  #carousel-container {
    > .current-figure {
      > #current-image, > #current-image-legacy {
        margin-bottom: 15px;
      }
    }
  }
  // profile
  .profile-details {
    img {
      margin-bottom: 15px;
    }
  }
  .project-status {
    margin-top: 15px;
    .media {
      margin-top: 15px;
    }
  }
  .profile-table {
    table {
      tbody {
        tr {
          td {
            &:first-child {
              min-width: 150px;
            }
          }
        }
      }
    }
  }
  .account-setting {
    h5 {
      margin-bottom: 15px;
    }
  }
  .deactivate-account {
    margin-top: 15px;
    .btn {
      margin-top: 15px;
    }
  }
  // create user
  .sm-label-radio {
    margin-bottom:  4px !important;
  }
  .permission-block {
    .attribute-blocks {
      .form-group {
        padding-bottom: 15px;
      }
    }
  }
  .needs-validation {
    textarea {
      margin: 0 10px;
    }
    h4 {
      margin-bottom: 15px;
    }
    .permission-block {
      .attribute-blocks {
        .row {
          padding-left: 15px;
        }
        + .attribute-blocks {
          h5 {
            margin-top: 20px;
          }
        }
      }
    }
    .radio_animated {
      margin:  0 6px 0 0;
    }
    .radio-animated {
      label {
        margin-right: 15px;
      }
    }
  }
  // tab css
  .tab-coupon {
    display: grid;
  }
  // translation
  .translation-list {
    table {
      tr {
        td, th {
          &:nth-child(3),
          &:nth-child(4) {
            width: 180px !important;
          }
        }
      }
    }
  }
  // datatable css
  .product-list {
    table {
      tr {
        td, th {
          width: 100px !important;
          &:nth-child(2) {
            width: 250px !important;
          }
          &:nth-child(3) {
            width: 120px !important;
          }
        }
      }
    }
  }
  .digital-product {
    table {
      tr {
        td, th {
          &:nth-child(2){
            width: 120px !important;
          }
        }
      }
    }
  }
  // add product css
  .add-product-form {
    .form-group {
      .form-control {
        width: 93%;
        margin: 0 auto;
      }
      label {
        padding: 0;
      }
    }
    .qty-box {
      width: 162px;
      .input-group {
        .form-control {
          width: 80px;
        }
      }
    }
    .description-sm {
      padding: 0 !important;
    }
  }
  .zoomContainer {
    top: 184px !important;
  }
  .add-product  {
    ul {
      li {
        .box-input-file, .imgUpload {
          width: 30px;
          height: 30px;
        }
      }
    }
  }
  // order
  .dataTables_wrapper {
    .dataTables_paginate {
      margin-top: 15px !important;
      margin-left: 0 !important;
      .paginate_button {
        padding: 1px 4px !important;
      }
    }
    .dataTables_length {
      margin-bottom: 15px;
    }
    table {
      &.dataTable {
        margin-top: 15px !important;
      }
    }
  }
}
@media (max-width: 420px) {
  .product-right {
    .timer {
      span {
        width: 45px;
      }
    }
  }
}
@media only screen and (max-width: 360px){
  .authentication-box {
    padding: 30px 15px;
    .forgot-pass {
      float: none;
      padding-top: 10px !important;
    }
    .container {
      .form-group {
          margin-bottom: 1rem;
      }
      h3 {
          font-size: 20px;
        }
      p {
        line-height: 1.6;
      }
      .svg-icon {
        padding: 17px;
        height: 90px;
        width: 90px;
        margin-bottom: 25px;
        svg {
          height: 50px;
        }
      }
      .bg-primary {
        padding: 30px 15px;
      }
    }
  }
  .input-grp-p {
    padding: 0 7px;
  }
  .custom-datatable {
    .ngx-datatable.bootstrap {
      .datatable-footer {
        .datatable-pager {
          a {
            min-width: 16px !important;
            line-height: 22px !important;
            font-size: 13px;
          }
        }
      }
    }
  }
  .nav-menus {
    .notification-badge {
      right: 0;
    }
  }
  .page-wrapper {
    .page-main-header {
      .main-header-right {
        .nav-right {
          >ul {
            >li {
              padding: 0 13px;
              .dot {
                right: 13px;
              }
            }
          }
        }
      }
    }
  }
  .footer{
    padding-left: 0;
    padding-right: 0 !important;
    p{
      font-size: 12px;
    }
  }
  .add-product {
    img {
      width: 300px;
    }
  }
  // product detail
  .product-page-main {
    .btn {
      padding: 5px 12px;
    }
  }
}
@media only screen and (max-height: 800px){
  .right-sidebar{
    .chat-box{
      .friend-list{
        max-height: calc(100vh - 150px);
        overflow: scroll;
      }
    }
  }
}

.form-control {
  border-color:grey;
}

/*=====================
  Responsive CSS End
==========================*/
